import { currentMonth, isUser } from "../helpers";
import { theme } from "../types";
import darkTheme from "../themes/darkSmas";

export interface state {
    loading?: boolean
    authenticated?: boolean
    disabled?: boolean
    theme?: theme
    isSettingsOpen?: boolean
    isDialogOpen?: boolean
    name?: string
    nameError?: string
    phone?: string
    phoneError?: string
    password?: string
    passwordError?: string
    confirmation?: string
    confirmationError?: string
    edit?: boolean
    id?: string
    altPhone?: string
    altPhoneError?: string
    street?: string
    streetError?: string
    region?: string
    regionError?: string
    location?: string
    locationError?: string
    page?: number
    limit?: number
    pages?: number
    list?: any[]
    next?: number
    previous?: number
    order?: number
    pagination?: number[]
    visible?: boolean
    dataObject?: any
    description?: string
    descriptionError?: string
    permissions?: string[]
    permissionsError?: string
    role?: string
    roleError?: string
    oldPassword?: string
    oldPasswordError?: string
    paid?: number
    paidError?: string
    date?: string
    dateError?: string
    type?: string
    typeError?: string
    amount?: number
    amountError?: string
    name2?: string
    name2Error?: string
    list2?: any[]
    id2?: string
    model?: string
    modelError?: string
    brand?: string
    brandError?: string
    imei?: string
    imeiError?: string
    key?: string
    keyError?: string
    valu?: string
    valuError?: string
    keys?: string[]
    values?: string[]
    service?: string
    serviceError?: string
    accessory?: string
    accessoryError?: string
    serviceCost?: number
    serviceCostError?: string
    accessoryCost?: number
    accessoryCostError?: string
    description2?: string
    description2Error?: string
    from?: string
    fromError?: string
    to?: string
    toError?: string,
    buying?: number
    buyingError?: string
    selling?: number
    sellingError?: string
    quantity?: number
    quantityError?: string
    stock?: number
    stockError?: string
    stockLimit?: number
    stockLimitError?: string
    name3?: string
    name3Error?: string
    list3?: any[]
    list5?: any[]
    month?: string
    year?: number
    purchases?: any[]
    expenses?: any[]
    services?: any[]
    payments?: any[]
    phone2?: string
    list4?: any[]
    customers?: any[]
    devices?: any[]
    users?: any[]
    roles?: any[]
    accessories?: any[]
    account?: string
    accountError?: string
    username?: string
    usernameError?: string
    sort?: string
    setting?: { theme: string, language: string, font: string }
    manufacturer?: string
    manufacturerError?: string
    supplier?: string
    supplierError?: string
    batch?: string
    batchError?: string
    unit?: string
    unitError?: string
    category?: string
    categoryError?: string
    manufacturedDate?: string
    manufacturedDateError?: string
    expireDate?: string
    expireDateError?: string
    condition?: string
    product?: string
    productError?: string
    customer?: string
    customerError?: string
    status?: string
    statusError?: string
    step?: 'step_one' | 'step_two' | 'step_three' | 'step_four' | 'step_five'
    stepsError?: string[]
    device?: string
    deviceError?: string
    productCost?: number
    productCostError?: string
    message?: string
    messageError?: string
    loadingMessage?: string
    sales?: any[]
    products?: any[]
    discount?: number
    tin?: string
    tinError?: string
    stores?: any[]
    storeProducts?: any[]
    store?: string
    storeError?: string
    pos?: 'order' | 'cart' | 'invoice' | 'note',
    file?: any
    fileError?: string
    suppliers?: any[]
    manufacturers?: any[]
    tax?: number
    incomeStatement?: any
    startDate?: string
    startDateError?: string
    endDate?: string
    endDateError?: string
    propsCondition?: any
    branchs?: any[]
    categories?: any[]
    units?: any[]
    batchs?: any[]
    debts?: any[]
    package?: string
    online?: boolean
    posSales?: any[]
    orders?: any[],
    expenseTypes?: any[]
    debtHistories?: any[]
    shops?: any[]
    activities?: any[]
    vendor?: string
    vendorError?: string
    vendors?: any[]
    apiKey?: string
}

export const initialState: state = {
    loading: false,
    disabled: false,
    authenticated: false,
    theme: darkTheme,
    isDialogOpen: false,
    isSettingsOpen: false,
    name: '',
    nameError: '',
    phone: '',
    phoneError: '',
    password: '',
    passwordError: '',
    confirmation: '',
    confirmationError: '',
    edit: false,
    id: '',
    region: '',
    regionError: '',
    location: '',
    locationError: '',
    altPhone: '',
    altPhoneError: '',
    street: "",
    streetError: "",
    page: 1,
    pages: 0,
    list: [],
    next: 0,
    previous: 0,
    limit: isUser ? 10 : 100,
    order: 1,
    pagination: [],
    visible: false,
    dataObject: null,
    description: '',
    descriptionError: '',
    permissions: [],
    permissionsError: '',
    role: '',
    roleError: '',
    oldPassword: '',
    oldPasswordError: '',
    paid: 0,
    paidError: '',
    date: '',
    dateError: '',
    type: '',
    typeError: '',
    amount: 0,
    amountError: '',
    name2: '',
    name2Error: '',
    list2: [],
    id2: '',
    model: '',
    modelError: '',
    brand: '',
    brandError: '',
    imei: '',
    imeiError: '',
    key: '',
    valu: '',
    keyError: '',
    valuError: '',
    keys: [],
    values: [],
    service: '',
    serviceError: '',
    serviceCost: 0,
    serviceCostError: '',
    accessory: '',
    accessoryError: '',
    accessoryCost: 0,
    accessoryCostError: '',
    description2: '',
    description2Error: '',
    from: '',
    fromError: '',
    to: '',
    toError: '',
    buying: 0,
    buyingError: '',
    selling: 0,
    sellingError: '',
    quantity: 0,
    quantityError: '',
    stock: 0,
    stockError: '',
    stockLimit: 0,
    stockLimitError: '',
    name3: '',
    name3Error: '',
    list3: [],
    list5: [],
    month: currentMonth(),
    year: new Date().getFullYear(),
    payments: [],
    purchases: [],
    expenses: [],
    services: [],
    phone2: '',
    list4: [],
    customers: [],
    users: [],
    accessories: [],
    devices: [],
    roles: [],
    account: '',
    accountError: '',
    username: '',
    usernameError: '',
    sort: '',
    setting: {
        theme: 'lightSmas',
        language: 'English',
        font: 'Product Sans'
    },
    manufacturer: '',
    manufacturerError: '',
    supplier: '',
    supplierError: '',
    batch: '',
    batchError: '',
    unit: '',
    unitError: '',
    category: '',
    categoryError: '',
    manufacturedDate: '',
    manufacturedDateError: '',
    expireDate: '',
    expireDateError: '',
    condition: '',
    product: '',
    productError: '',
    customer: '',
    customerError: '',
    status: '',
    statusError: '',
    step: 'step_one',
    stepsError: [],
    device: '',
    deviceError: '',
    productCost: 0,
    productCostError: '',
    message: '',
    messageError: '',
    loadingMessage: '',
    sales: [],
    products: [],
    discount: 0,
    tin: '',
    tinError: '',
    stores: [],
    storeProducts: [],
    store: '',
    storeError: '',
    pos: 'cart',
    file: '',
    fileError: '',
    suppliers: [],
    manufacturers: [],
    tax: 18,
    incomeStatement: {},
    startDate: '',
    startDateError: '',
    endDate: '',
    endDateError: '',
    propsCondition: {},
    branchs: [],
    categories: [],
    units: [],
    batchs: [],
    debts: [],
    package: '',
    online: true,
    posSales: [],
    orders: [],
    expenseTypes: [],
    debtHistories: [],
    shops: [],
    activities: [],
    vendor: "",
    vendorError: "",
    vendors: [],
    apiKey: "f98621912e1208ea11195b2d153ab1",
}