import React, { lazy } from 'react'
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import Overlay from '../components/overlay';
import Bootstrap from '../components/bootstrap';
import SettingsToggle from '../components/settings/setting-toggle';
import Settings from '../components/settings/settings';
import reducer from './../hooks/reducer'
import { initialState } from '../hooks/state';
import Navbar from '../components/navigation/navbar';
import useStyledTheme from '../hooks/useStyledTheme';
import Routing from './routing';

const Login = lazy(() => import('../screens/user/login'))
const Register = lazy(() => import('../screens/user/register'))
const ForgotPassword = lazy(() => import('../screens/user/forgotPassword'))
const VerificationCode = lazy(() => import('../screens/user/verificationCode'))
const ResetPassword = lazy(() => import('../screens/user/resetPassword'))



const StyledWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: ${(props: any) => props.theme.screenWidthMd}) {
    flex-direction: row;
  }
`

const RouteWrapper: React.FunctionComponent<any> = ({ setTheme, toggleNavbar, authenticated, application }: any) => {
    const theme: any = useStyledTheme()
    const [state, dispatch] = React.useReducer(reducer, initialState)
    const isOverlayVisible: boolean = state.isSettingsOpen || theme.isNavbarOpen

    function handleOverlayClicked(): void {
        dispatch({ type: 'isSettingsOpen', value: { isSettingsOpen: false } })
        toggleNavbar(false)
    }

    return (
        <StyledWrapper>
            <Overlay
                zIndex={5}
                onClick={handleOverlayClicked}
                isVisible={isOverlayVisible}
            />
            <Bootstrap />
            {
                authenticated ?
                    <Navbar toggleNavbar={toggleNavbar} />
                    : null
            }
            <SettingsToggle
                className='hide-on-print'
                onClick={() => {
                    dispatch({ type: 'isSettingsOpen', value: { isSettingsOpen: true } })
                    toggleNavbar(false)
                }} />
            <Settings
                className='hide-on-print'
                isSettingsOpen={state.isSettingsOpen}
                setSettingsOpen={() => dispatch({ type: 'isSettingsOpen', value: { isSettingsOpen: true } })}
                setTheme={setTheme}
            />
            {
                authenticated
                    ? Routing(application)
                    : <Switch>
                        <Route exact path="/login" render={(props) => <Login {...props} />} />
                        <Route exact path="/register" render={(props) => <Register {...props} />} />
                        <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
                        <Route exact path="/verification-code" render={(props) => <VerificationCode {...props} />} />
                        <Route exact path="/reset-password" render={(props) => <ResetPassword {...props} />} />
                        <Route exact path="*" render={(props) => <Login {...props} />} />
                    </Switch>
            }
        </StyledWrapper>
    )
}

export default RouteWrapper