import { createGlobalStyle } from "styled-components";

const Bootstrap: any = createGlobalStyle`
.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 85%;
  }
}

.container-fluid {
  width: 100%;
  padding-right: ${(p: any) => p.theme.gutterWidth};
  padding-left: ${(p: any) => p.theme.gutterWidth};
  margin-right: auto;
  margin-left: auto;
}

.col .row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}


.row {
  margin-left: auto;
  margin-right: auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.row .col {
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  // padding: 0 0.75rem;
  min-height: 1px;
}

.row .col[class*="push-"], .row .col[class*="pull-"] {
  position: relative;
}

.row .col.s1 {
  width: 8.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s2 {
  width: 16.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s4 {
  width: 33.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s5 {
  width: 41.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s7 {
  width: 58.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s8 {
  width: 66.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s10 {
  width: 83.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s11 {
  width: 91.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.offset-s1 {
  margin-left: 8.3333333333%;
}

.row .col.pull-s1 {
  right: 8.3333333333%;
}

.row .col.push-s1 {
  left: 8.3333333333%;
}

.row .col.offset-s2 {
  margin-left: 16.6666666667%;
}

.row .col.pull-s2 {
  right: 16.6666666667%;
}

.row .col.push-s2 {
  left: 16.6666666667%;
}

.row .col.offset-s3 {
  margin-left: 25%;
}

.row .col.pull-s3 {
  right: 25%;
}

.row .col.push-s3 {
  left: 25%;
}

.row .col.offset-s4 {
  margin-left: 33.3333333333%;
}

.row .col.pull-s4 {
  right: 33.3333333333%;
}

.row .col.push-s4 {
  left: 33.3333333333%;
}

.row .col.offset-s5 {
  margin-left: 41.6666666667%;
}

.row .col.pull-s5 {
  right: 41.6666666667%;
}

.row .col.push-s5 {
  left: 41.6666666667%;
}

.row .col.offset-s6 {
  margin-left: 50%;
}

.row .col.pull-s6 {
  right: 50%;
}

.row .col.push-s6 {
  left: 50%;
}

.row .col.offset-s7 {
  margin-left: 58.3333333333%;
}

.row .col.pull-s7 {
  right: 58.3333333333%;
}

.row .col.push-s7 {
  left: 58.3333333333%;
}

.row .col.offset-s8 {
  margin-left: 66.6666666667%;
}

.row .col.pull-s8 {
  right: 66.6666666667%;
}

.row .col.push-s8 {
  left: 66.6666666667%;
}

.row .col.offset-s9 {
  margin-left: 75%;
}

.row .col.pull-s9 {
  right: 75%;
}

.row .col.push-s9 {
  left: 75%;
}

.row .col.offset-s10 {
  margin-left: 83.3333333333%;
}

.row .col.pull-s10 {
  right: 83.3333333333%;
}

.row .col.push-s10 {
  left: 83.3333333333%;
}

.row .col.offset-s11 {
  margin-left: 91.6666666667%;
}

.row .col.pull-s11 {
  right: 91.6666666667%;
}

.row .col.push-s11 {
  left: 91.6666666667%;
}

.row .col.offset-s12 {
  margin-left: 100%;
}

.row .col.pull-s12 {
  right: 100%;
}

.row .col.push-s12 {
  left: 100%;
}

@media only screen and (min-width: 601px) {
  .row .col.m1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-m1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-m1 {
    right: 8.3333333333%;
  }
  .row .col.push-m1 {
    left: 8.3333333333%;
  }
  .row .col.offset-m2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-m2 {
    right: 16.6666666667%;
  }
  .row .col.push-m2 {
    left: 16.6666666667%;
  }
  .row .col.offset-m3 {
    margin-left: 25%;
  }
  .row .col.pull-m3 {
    right: 25%;
  }
  .row .col.push-m3 {
    left: 25%;
  }
  .row .col.offset-m4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-m4 {
    right: 33.3333333333%;
  }
  .row .col.push-m4 {
    left: 33.3333333333%;
  }
  .row .col.offset-m5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-m5 {
    right: 41.6666666667%;
  }
  .row .col.push-m5 {
    left: 41.6666666667%;
  }
  .row .col.offset-m6 {
    margin-left: 50%;
  }
  .row .col.pull-m6 {
    right: 50%;
  }
  .row .col.push-m6 {
    left: 50%;
  }
  .row .col.offset-m7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-m7 {
    right: 58.3333333333%;
  }
  .row .col.push-m7 {
    left: 58.3333333333%;
  }
  .row .col.offset-m8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-m8 {
    right: 66.6666666667%;
  }
  .row .col.push-m8 {
    left: 66.6666666667%;
  }
  .row .col.offset-m9 {
    margin-left: 75%;
  }
  .row .col.pull-m9 {
    right: 75%;
  }
  .row .col.push-m9 {
    left: 75%;
  }
  .row .col.offset-m10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-m10 {
    right: 83.3333333333%;
  }
  .row .col.push-m10 {
    left: 83.3333333333%;
  }
  .row .col.offset-m11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-m11 {
    right: 91.6666666667%;
  }
  .row .col.push-m11 {
    left: 91.6666666667%;
  }
  .row .col.offset-m12 {
    margin-left: 100%;
  }
  .row .col.pull-m12 {
    right: 100%;
  }
  .row .col.push-m12 {
    left: 100%;
  }
}

@media only screen and (min-width: 993px) {
  .row .col.l1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-l1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-l1 {
    right: 8.3333333333%;
  }
  .row .col.push-l1 {
    left: 8.3333333333%;
  }
  .row .col.offset-l2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-l2 {
    right: 16.6666666667%;
  }
  .row .col.push-l2 {
    left: 16.6666666667%;
  }
  .row .col.offset-l3 {
    margin-left: 25%;
  }
  .row .col.pull-l3 {
    right: 25%;
  }
  .row .col.push-l3 {
    left: 25%;
  }
  .row .col.offset-l4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-l4 {
    right: 33.3333333333%;
  }
  .row .col.push-l4 {
    left: 33.3333333333%;
  }
  .row .col.offset-l5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-l5 {
    right: 41.6666666667%;
  }
  .row .col.push-l5 {
    left: 41.6666666667%;
  }
  .row .col.offset-l6 {
    margin-left: 50%;
  }
  .row .col.pull-l6 {
    right: 50%;
  }
  .row .col.push-l6 {
    left: 50%;
  }
  .row .col.offset-l7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-l7 {
    right: 58.3333333333%;
  }
  .row .col.push-l7 {
    left: 58.3333333333%;
  }
  .row .col.offset-l8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-l8 {
    right: 66.6666666667%;
  }
  .row .col.push-l8 {
    left: 66.6666666667%;
  }
  .row .col.offset-l9 {
    margin-left: 75%;
  }
  .row .col.pull-l9 {
    right: 75%;
  }
  .row .col.push-l9 {
    left: 75%;
  }
  .row .col.offset-l10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-l10 {
    right: 83.3333333333%;
  }
  .row .col.push-l10 {
    left: 83.3333333333%;
  }
  .row .col.offset-l11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-l11 {
    right: 91.6666666667%;
  }
  .row .col.push-l11 {
    left: 91.6666666667%;
  }
  .row .col.offset-l12 {
    margin-left: 100%;
  }
  .row .col.pull-l12 {
    right: 100%;
  }
  .row .col.push-l12 {
    left: 100%;
  }
}

@media only screen and (min-width: 1201px) {
  .row .col.xl1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-xl1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-xl1 {
    right: 8.3333333333%;
  }
  .row .col.push-xl1 {
    left: 8.3333333333%;
  }
  .row .col.offset-xl2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-xl2 {
    right: 16.6666666667%;
  }
  .row .col.push-xl2 {
    left: 16.6666666667%;
  }
  .row .col.offset-xl3 {
    margin-left: 25%;
  }
  .row .col.pull-xl3 {
    right: 25%;
  }
  .row .col.push-xl3 {
    left: 25%;
  }
  .row .col.offset-xl4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-xl4 {
    right: 33.3333333333%;
  }
  .row .col.push-xl4 {
    left: 33.3333333333%;
  }
  .row .col.offset-xl5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-xl5 {
    right: 41.6666666667%;
  }
  .row .col.push-xl5 {
    left: 41.6666666667%;
  }
  .row .col.offset-xl6 {
    margin-left: 50%;
  }
  .row .col.pull-xl6 {
    right: 50%;
  }
  .row .col.push-xl6 {
    left: 50%;
  }
  .row .col.offset-xl7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-xl7 {
    right: 58.3333333333%;
  }
  .row .col.push-xl7 {
    left: 58.3333333333%;
  }
  .row .col.offset-xl8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-xl8 {
    right: 66.6666666667%;
  }
  .row .col.push-xl8 {
    left: 66.6666666667%;
  }
  .row .col.offset-xl9 {
    margin-left: 75%;
  }
  .row .col.pull-xl9 {
    right: 75%;
  }
  .row .col.push-xl9 {
    left: 75%;
  }
  .row .col.offset-xl10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-xl10 {
    right: 83.3333333333%;
  }
  .row .col.push-xl10 {
    left: 83.3333333333%;
  }
  .row .col.offset-xl11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-xl11 {
    right: 91.6666666667%;
  }
  .row .col.push-xl11 {
    left: 91.6666666667%;
  }
  .row .col.offset-xl12 {
    margin-left: 100%;
  }
  .row .col.pull-xl12 {
    right: 100%;
  }
  .row .col.push-xl12 {
    left: 100%;
  }
}


.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: ${(p: any) => p.theme.gutterWidth};
  padding-left: ${(p: any) => p.theme.gutterWidth};
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: ${(p: any) => p.theme.screenWidthXs}) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: ${(p: any) => p.theme.screenWidthSm}) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: ${(p: any) => p.theme.screenWidthMd}) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: ${(p: any) => p.theme.screenWidthLg}) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
@media only screen and (max-width: 600px) {
  .hide-on-small-only, .hide-on-small-and-down {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .hide-on-med-only {
    display: none !important;
  }
}

@media only screen and (min-width: 993px) {
  .hide-on-large-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .hide-on-extra-large-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .show-on-extra-large {
    display: block !important;
  }
}

@media only screen and (min-width: 993px) {
  .show-on-large {
    display: block !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .show-on-medium {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .show-on-small {
    display: block !important;
  }
}

@media only screen and (min-width: 601px) {
  .show-on-medium-and-up {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  .show-on-medium-and-down {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .center-on-small-only {
    text-align: center;
  }
}

  .hide {
    display: none !important;
  }

  .left-align {
    text-align: left;
  }

  .right-align {
    text-align: right;
  }

  .center, .center-align {
    text-align: center;
  }

  .left {
    float: left !important;
  }

  .right {
    float: right !important;
  }

.no-select, input[type=range],
input[type=range] + .thumb {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.circle {
  border-radius: 50%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}
.toast {
  border-radius: ${(props) => props.theme.borderRadius};
  top: 35px;
  width: auto;
  margin-top: 10px;
  position: relative;
  max-width: 100%;
  height: auto;
  min-height: 48px;
  line-height: 1.5em;
  background-color: ${(props) => props.theme.colorPrimary};
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
  color: ${(props) => props.theme.colorPrimaryText};
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: default;
}

.toast .toast-action {
  color: #eeff41;
  font-weight: 500;
  margin-right: -25px;
  margin-left: 3rem;
}

.toast.rounded {
  border-radius: 24px;
}

@media only screen and (max-width: 600px) {
  .toast {
    width: 100%;
    border-radius: 0;
  }
}

#toast-container {
  display: block;
  position: fixed;
  z-index: 10000;
}

@media only screen and (max-width: 600px) {
  #toast-container {
    min-width: 100%;
    bottom: 0%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  #toast-container {
    left: 5%;
    bottom: 7%;
    max-width: 90%;
  }
}

@media only screen and (min-width: 993px) {
  #toast-container {
    top: 10%;
    right: 7%;
    max-width: 86%;
  }
}

/* Customize the label (the container) */
.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 20px;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  opacity: 0.85;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: ${(props) => props.theme.colorPrimary};
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.material-tooltip {
    padding: 10px 8px;
    font-size: 1rem;
    z-index: 2000;
    border-radius: ${(props) => props.theme.borderRadius};
    color: ${(props) => props.theme.colorPrimaryText};
    min-height: 36px;
    line-height: 120%;
    opacity: 0;
    position: absolute;
    text-align: center;
    max-width: calc(100% - 4px);
    overflow: hidden;
    left: 0;
    top: 0;
    pointer-events: none;
    visibility: hidden;
    background-color: ${(props) => props.theme.colorPrimary};
    text-transform: capitalize;
    font-weight: 300;
}
 
.sticky {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background: ${(props) => props.theme.colorBackground};
}

.pointer {
  cursor: pointer;

}

.pointer:hover {
  text-decoration: underline
}

.lower {
  text-transform: lowercase !important
}

.materialboxed {
  display: block;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  position: relative;
  -webkit-transition: opacity .4s;
  transition: opacity .4s;
  -webkit-backface-visibility: hidden;
}

.materialboxed:hover:not(.active) {
  opacity: .8;
}

.materialboxed.active {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.smallLoader {
  display: inline-block;
  position: relative;
  padding-top: 10px;
}
.smallLoader div {
  position: absolute;
  top: 50%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${(props) => props.theme.colorPrimaryText};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.smallLoader div:nth-child(1) {
  left: 1px;
  animation: smallLoader1 0.6s infinite;
}
.smallLoader div:nth-child(2) {
  left: 1px;
  animation: smallLoader2 0.6s infinite;
}
.smallLoader div:nth-child(3) {
  left: 25px;
  animation: smallLoader2 0.6s infinite;
}
.smallLoader div:nth-child(4) {
  left: 47px;
  animation: smallLoader3 0.6s infinite;
}
@keyframes smallLoader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes smallLoader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes smallLoader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.show-on-print {
  display: none !important;
}

@media print {
  .show-on-print {
    display: block !important;
  }
  .hide-on-print {
    display: none !important;
  }
  @page {
    size: A4 !important;
    margin: 25px !important;
  }
}

.report-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid ${(props) => props.theme.colorPrimary};
  padding-bottom: 10px;
}

.report-header .report-left, .report-header .report-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.report-header .report-left .logo {

}

.report-header .report-left .logo img {
  
}

.report-header .report-left .report-title {
  font-size: 32px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 5px 10px;
}

.report-header .report-left .report-type {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 300;
  margin-left: 10px;
}

.report-header .report-right .company {
  font-weight: 500;
  font-weight: 24px;
}

.report-header .report-right .region, .report-header .report-right .street {
  margin: 2.5px 0;
  font-weight: 300;
  font-size: 18px;
}

.report-header .report-right .po-box {
  font-weight: 300;
  font-size: 18px;
}

form {
  padding: ${(props) => props.theme.lenMd3}
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

`;

export default Bootstrap;
