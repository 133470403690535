import styled, { keyframes } from "styled-components";
import { rgba } from "polished";


const StyledWrapper = styled.button`
  position: fixed;
  z-index: 5;
  right: 0;
  top: 50%;
  border-top-left-radius: ${(p) => p.theme.lenLg1};
  border-bottom-left-radius: ${(p) => p.theme.lenLg1};
  transform: translateY(-500%);
  transform: translateX(15%);
  background: ${(p) => p.theme.colorPrimary};
  color: #ffffff;
  box-shadow: 0px 0px 10px ${rgba("black", 0.12)};
`;

const wiggle = keyframes`
  0% { transform: rotate(180deg); }
`;

const StyledIcon = styled.span`
  display: block;
  font-size: 1.25em !important;
  animation: ${wiggle} 1.375s infinite;
  padding: 10px;
`;

const SettingsToggle: any = ({ onClick }: any) => {
    return (
        <StyledWrapper onClick={onClick} className="hide-on-print">
            <StyledIcon className="material-icons-round">
              settings
            </StyledIcon>
        </StyledWrapper>
    );
};

export default SettingsToggle;
