import { Toast } from '../components/toast'
import { response, routes } from '../types'
import { Encrypt } from './encryption'
import { Branch, models, reFormatText, Url, User } from './index'

const branch: any = Branch('_id')
const user: any = User('_id')
const headers = {
    "Content-Type": "application/json",
    "Authorization": "IjE4ODIzMTgxJDJhJDEwJFNXY1dOQ0dsN2ttVjhMSGN5L1QwUE9BbFBzRC82MlVnZ3YzTmVpcE1GeDdaeEt2TlBGdkNDZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5MjUyMjY0NyI=",
    "branch": branch ? Encrypt(branch) : "IjE4ODIzMTgxJDJhJDEwJFNXY1dOQ0dsN2ttVjhMSGN5L1QwUE9BbFBzRC82MlVnZ3YzTmVpcE1GeDdaeEt2TlBGdkNDZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5MjUyMjY0NyI="
}


class crud {
    public async read({ route }: routes, parameters: any): Promise<response> {
        try {
            let response: any = await fetch(`${Url()}/api/${route}?${parameters}`, {
                headers
            })
            response = await response.json()

            if (response.success)
                return { success: true, message: response.message }
            else
                return { success: false, message: response.message }

        } catch (error) {
            if (error instanceof Error)
                return { success: false, message: error.message }
            else
                return { success: false, message: error }
        }
    }

    public async create({ route }: routes, body: any): Promise<response> {
        try {
            let response: any = await fetch(`${Url()}/api/${route}`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(body),
                headers
            })

            response = await response.json()
            if (response.success) {
                this.createActivity(body.model, response.message, 'create')
                return { success: true, message: response.message }
            }
            else
                return { success: false, message: response.message }

        } catch (error) {
            if (error instanceof Error)
                return { success: false, message: error.message }
            else
                return { success: false, message: error }
        }
    }

    public async update({ route }: routes, body: any): Promise<response> {
        try {
            let response: any = await fetch(`${Url()}/api/${route}`, {
                method: 'PUT',
                mode: 'cors',
                body: JSON.stringify(body),
                headers
            })
            response = await response.json()

            if (response.success) {
                if (body.model !== 'user') {
                    this.createActivity(body.model, response.message, 'update')
                }

                return { success: true, message: response.message }
            }
            else
                return { success: false, message: response.message }

        } catch (error) {
            if (error instanceof Error)
                return { success: false, message: error.message }
            else
                return { success: false, message: error }
        }
    }

    public async delete(parameters: any): Promise<response> {
        try {
            let response: any = await fetch(`${Url()}/api/${'delete'}?${parameters}&action=delete`, {
                method: 'DELETE',
                mode: 'cors',
                headers
            })
            response = await response.json()

            if (response.success) {
                let modelName: string = ''
                for (let model of models) {
                    if (parameters.split('&').includes(`model=${model}`)) {
                        modelName = model
                    }
                }
                this.createActivity(modelName, response.message, 'delete')
                return { success: true, message: response.message }
            }
            else
                return { success: false, message: response.message }

        } catch (error) {
            if (error instanceof Error)
                return { success: false, message: error.message }
            else
                return { success: false, message: error }
        }
    }

    public async restore(parameters: any): Promise<response> {
        try {
            let response: any = await fetch(`${Url()}/api/${'delete'}?${parameters}&action=restore`, {
                method: 'DELETE',
                mode: 'cors',
                headers
            })
            response = await response.json()

            if (response.success) {
                let modelName: string = ''
                for (let model of models) {
                    if (parameters.split('&').includes(`model=${model}`)) {
                        modelName = model
                    }
                }
                this.createActivity(modelName, response.message, 'restore')
                return { success: true, message: response.message }
            }
            else
                return { success: false, message: response.message }

        } catch (error) {
            if (error instanceof Error)
                return { success: false, message: error.message }
            else
                return { success: false, message: error }
        }
    }

    private async createActivity(model: string, data: any, action: 'create' | 'update' | 'delete' | 'restore'): Promise<void> {
        try {
            if (model !== 'activity') {
                const body: object = {
                    model: 'activity',
                    branch: Branch('_id'),
                    user,
                    data,
                    restored: action === 'restore' || ((model === 'debt') && !data.editable) ? true : false,
                    type: action === 'create' ? 'primary' : action === 'update' ? 'info' : action === 'delete' ? 'danger' : 'warn',
                    activity_model: model,
                    name: action === 'create' ? `new ${reFormatText(model)} was created` : action === 'update' ? `${reFormatText(model)} data was modified` : action === 'delete' ? `${reFormatText(model)} data was deleted` : `${reFormatText(model)} data was restored`
                }
                this.create({ route: 'create' }, body)
            }
        } catch (error) {
            if (error instanceof Error) {
                Toast(error.message)
            }
            else {
                console.error(error)
            }
        }
    }

}

const CRUD = new crud()

export default CRUD