import React, { Suspense } from 'react'
import Loader from './components/loader'
import { Toast } from './components/toast'
import { Retrive, Store, User } from './helpers'
import CRUD from './helpers/crud'
import reducer from './hooks/reducer'
import { initialState } from './hooks/state'
import { theme } from './types'
import darkSmasTheme from './themes/darkSmas'
import lightSmasTheme from './themes/lightSmas'
import { BrowserRouter as Router } from "react-router-dom"
import RouteWrapper from './routes/routeWrapper'
import AuthContext from './hooks/authContext'
import ThemeWrapper from './themes/themeProvider'
import register from './webpush'
import Offline from './screens/pages/offline'
import API from './helpers/api'
import functions from './helpers/functions'

export default function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    window.addEventListener('online', () => {
      dispatch({ type: 'online', value: { online: true } })
    })
    window.addEventListener('offline', () => {
      dispatch({ type: 'online', value: { online: false } })
    })
    dispatch({ type: 'online', value: { online: true } }) // window.navigator.onLine
    register()
    mount()
    return () => { }
    // eslint-disable-next-line
  }, [])

  function mount(): void {
    try {
      const user: any = User()
      if (user) {
        dispatch({ type: 'authenticated', value: { authenticated: true } })
        Store({ key: 'branch', value: user.branch })
        Store({ key: 'role', value: user.role })
        Store({ key: 'theme', value: user.setting.theme })
        Store({ key: 'language', value: user.setting.language })
      }
      else {
        localStorage.clear()
      }

      const themes: theme[] = [
        darkSmasTheme,
        lightSmasTheme,
      ]

      let currentTheme: any = Retrive({ key: 'theme' }) || 'lightSmas'
      if (((new Date().getHours()) >= 19) || ((new Date().getHours()) <= 6)) {
        currentTheme = 'darkSmas'
      }
      else {
        currentTheme = 'lightSmas'
      }
      const language: any = Retrive({ key: 'language' }) || 'English'
      const font: any = Retrive({ key: 'font' }) || 'Product Sans'
      const userTheme: theme = themes.filter((theme: theme) => theme.id === currentTheme)[0]
      handleThemeSet(userTheme, font, language)
    } catch (error) {
      if (error instanceof Error)
        Toast(error.message)
      else
        console.log(error)
    }
  }

  function handleThemeSet(theme: theme, fontFamily: string, language: 'English' | 'Swahili'): void {
    Store({ key: 'theme', value: theme.id })
    Store({ key: 'font', value: fontFamily })
    Store({ key: 'language', value: language })
    dispatch({ type: 'theme', value: { theme: { ...theme, language, fontFamily } } })
    updateSetting(theme.id, language, fontFamily)
  }

  async function updateSetting(theme: string, language: string, font: string): Promise<void> {
    try {
      const user: any = User()
      if (user) {
        const updatingBody: object = {
          model: 'user',
          condition: { _id: user._id },
          body: {
            setting: {
              theme,
              language,
              font
            }
          }
        }
        const response: any = await CRUD.update({ route: 'update' }, updatingBody)

        if (response.success) {
          Store({ key: 'user', value: response.message })
          Store({ key: 'branch', value: response.message.branch })
          Store({ key: 'role', value: response.message.role })
          Store({ key: 'theme', value: response.message.setting.theme })
          Store({ key: 'language', value: response.message.setting.language })
          dispatch({ type: 'authenticated', value: { authenticated: true } })
        }
      }
    } catch (error) {
      if (error instanceof Error)
        Toast(error.message)
      else
        console.log(error)
    }
  }

  function toggleNavbar(isOpen: boolean): void {
    dispatch({ type: 'theme', value: { theme: { ...state.theme, isNavbarOpen: isOpen } } })
  }

  const context = React.useMemo(() => ({
    authenticate: (user: any) => {
      Store({ key: 'user', value: user })
      Store({ key: 'branch', value: user.branch })
      Store({ key: 'role', value: user.role })
      Store({ key: 'theme', value: user.setting.theme })
      Store({ key: 'language', value: user.setting.language })
      Store({ key: 'font', value: user.setting.font })
      dispatch({ type: 'authenticated', value: { authenticated: true } })
      window.location.pathname = "/settings/view-profile"
      Toast('welcome you have been logged in')
    },

    logout: async () => {
      try {
        dispatch({ type: 'loading', value: { loading: true } })
        const body: object = { user: User('_id') }
        const response: any = await CRUD.create({ route: 'auth-logout' }, body)

        if (response.success) {
          dispatch({ type: 'loading', value: { loading: false } })
          dispatch({ type: 'authenticated', value: { authenticated: false } })
          localStorage.clear()
          Toast(response.message)
          window.location.href = 'https://er-s.net'
        }
        else {
          dispatch({ type: 'loading', value: { loading: false } })
          localStorage.clear()
          window.location.href = 'https://er-s.net'
          Toast(response.message)
        }
      } catch (error) {
        dispatch({ type: 'loading', value: { loading: false } })
        Toast(error.message)
      }
    }
  }), [])

  // HANDLING INPUT CHANGE
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    try {
      // destructure input name and value 
      const { name, value }: any = event.target
      // update state 
      dispatch({ type: name, value: { [name]: value } })
    } catch (error) {
      if (error instanceof Error)
        Toast(error.message)
      else
        console.error(error)
    }
  }

  return (
    <AuthContext.Provider value={context}>
      <ThemeWrapper theme={state?.theme}>
        {/* {state.loading ? <Loader /> : null} */}
        {
          state.online
            ?
            <Suspense fallback={<Loader />}>
              <Router>
                <RouteWrapper
                  setTheme={handleThemeSet}
                  theme={state?.theme}
                  toggleNavbar={toggleNavbar}
                  isNavbarOpen={state?.theme?.isNavbarOpen}
                  authenticated={state?.authenticated}
                  application={{
                    reducer: { state, dispatch },
                    handleInputChange,
                    api: API,
                    functions,
                  }}
                />
              </Router>
            </Suspense>
            : <Offline />
        }
      </ThemeWrapper>
    </AuthContext.Provider>
  )
}