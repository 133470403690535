import styled, { keyframes } from 'styled-components'

const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    opacity: 1;
    transition-property: visibility, opacity;
    transition-duration: 0.35s;
    transform: translate(-50%, -50%);
    background: ${(props) => props.theme.colorBackground};
    z-index: 999;
    transition: all 0.3s ease-in;
`
const ThemeWrapper = styled.div`
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease;
`

const Spinner = keyframes`
from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinerWrapper = styled.div`
    border-width: 3px;
    border-style: dotted inset none none;
    border-color: ${(props) => props.theme.colorPrimary};
    animation: ${Spinner} .8s infinite linear;
    width: 75px;
    min-width: 75px;
    height: 75px;
    min-height: 75px;
    border-radius: 50%;
`
const LogoWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -20%);
`

// const StyledSvg: any = styled.svg`
// fill: ${(props: any) => props.theme.colorPrimary}`;

// const StyledPath: any = styled.path`
//   fill: ${(props: any) => props.theme.colorPrimary};
// `;
// const StyledCircle = styled.circle``

const Loader: React.FunctionComponent<any> = () => {
    return (
        <Wrapper>
            <ThemeWrapper>
                <LogoWrapper>
                    {/* <StyledSvg width="120" height="120" viewBox="0 0 60 60">
                        <StyledPath
                            d="M23 11.01L18 11c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-9c0-.55-.45-.99-1-.99zM23 20h-5v-7h5v7zM20 2H2C.89 2 0 2.89 0 4v12c0 1.1.89 2 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4h18v5h2V4c0-1.11-.9-2-2-2zm-8.03 7L11 6l-.97 3H7l2.47 1.76-.94 2.91 2.47-1.8 2.47 1.8-.94-2.91L15 9h-3.03z"
                        />
                    </StyledSvg> */}
                </LogoWrapper>
                <SpinerWrapper />
            </ThemeWrapper>
        </Wrapper>
    )
}

export default Loader