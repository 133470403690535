import styled from "styled-components";

const StyledWrapper: any = styled.div`
  position: fixed;
  z-index: 15;
  right: 0;
  top: 0;
  width: 250px;
  height: 100%;
  padding: ${(p: any) => p.theme.lenMd3};
  transform: translateX(${(p: any) => (p.isOpen ? "0%" : "1000%")});
  background: ${(p: any) => p.theme.colorNavbar};
  transition: transform 0.3s;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none
}
`;

const SettingsDialog: any = ({ children, isSettingsOpen }: any) => {
    return <StyledWrapper  isOpen={isSettingsOpen}>{children}</StyledWrapper>;
};

export default SettingsDialog;
