import path from 'path'
import { Toast } from './components/toast'
import { Branch, User } from './helpers'
import CRUD from './helpers/crud'

export default function register() {
    const publicKey: string = 'BF-MlH9ab1HOAQjVh4aaUaWHFje-oS0K_K1Kf1eXyvLFcz-1XN01VoCm9MWWBqt7AYuEkNjlJrm2sEhx70exfxU'
    // const privateKey: string = 'sitHSnHB3BcOdRcwt0L8Bs_6bJrQHT8RTl9-l5ueLys'

    if ('Notification' in window && (navigator.serviceWorker) && (User()))
        serviceWorkerRegister()

    async function serviceWorkerRegister() {
        try {
            const register: ServiceWorkerRegistration = await navigator.serviceWorker.register(path.join(__dirname, '/js/sw.js'))

            if (register) {
                const notification: NotificationPermission = await Notification.requestPermission(status => status)
                if (notification === 'granted') {
                    const subscription: PushSubscription | null = await register.pushManager.getSubscription()
                    if (subscription === null) {
                        const subscribe: PushSubscription = await register.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlBase64ToUint8Array(publicKey)
                        })
                        sendToServer(subscribe)
                    }
                }
            }

        } catch (error) {
            const registration: ServiceWorkerRegistration | undefined = await navigator.serviceWorker.getRegistration()

            if (registration) {
                registration.unregister()
            }
            if (error instanceof Error) {
                Toast(error.message)
            }
            else {
                console.error(error)
            }
        }
    }
}

function urlBase64ToUint8Array(base64String: string): any {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        // eslint-disable-next-line 
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}

async function sendToServer(subscribe: PushSubscription): Promise<void> {
    try {
        let body: object = {
            subscribing: subscribe,
            user: User('_id'),
            branch: Branch('_id')
        }

        const response: any = await CRUD.create({ route: 'web-push' }, body)

        if (!response.success) {
            const registration: ServiceWorkerRegistration | undefined = await navigator.serviceWorker.getRegistration()

            if (registration) {
                registration.unregister()
            }
        }

    } catch (error) {
        const registration: ServiceWorkerRegistration | undefined = await navigator.serviceWorker.getRegistration()

        if (registration) {
            registration.unregister()
        }
        if (error instanceof Error) {
            Toast(error.message)
        }
        else {
            console.error(error)
        }

    }
}
