import styled from "styled-components";

const StyledHeader: any = styled.div`
  color: ${(p: any) => p.theme.colorText};
  font-size: ${(p: any) => p.theme.lenMd3};
  margin-bottom: ${(p: any) => p.theme.lenMd3};
  text-transform: capitalize;
`;

const SettingsHeader: any = ({ children }: any) => {
  return <StyledHeader>{children || "Settings"}</StyledHeader>;
};

export default SettingsHeader;
