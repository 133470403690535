import { Decrypt, Encrypt } from "../helpers/encryption"
import { shopType, store } from "../types"
import moment from "moment"
import Translate from "./translator"
import { Toast } from "../components/toast"

export function Url(): string {
    if (process.env.NODE_ENV === 'development')
        return 'http://localhost:1016'
    else
        return 'https://ers.er-s.pro'
}

export const AppName: string = "ERS"

export function Store({ key, value }: store): boolean {
    if (key && value) {
        localStorage.setItem(Encrypt(key), Encrypt(value))
        return true
    }
    else {
        return false
    }
}

export function Retrive({ key }: store): any {
    if (key) {
        const item: any = localStorage.getItem(Encrypt(key))
        if (item)
            return Decrypt(item)
        else
            return false
    }
    else
        return false
}

export function User(info?: string | undefined): string | undefined {
    try {
        if (info) {
            const user: any = Retrive({ key: 'user' })
            if (user)
                return user[info]
            else
                return undefined
        }
        else {
            const user: string | undefined = Retrive({ key: 'user' })
            if (user)
                return user
            else
                return undefined
        }
    } catch (error) {
        return undefined
    }
}

export function Branch(info?: string | undefined): string | undefined {
    try {
        if (info) {
            const branch: any = Retrive({ key: 'branch' })
            if (branch)
                return branch[info]
            else
                return undefined
        }
        else {
            const branch: string | undefined = Retrive({ key: 'branch' })
            if (branch)
                return branch
            else
                return undefined
        }
    } catch (error) {
        return undefined
    }
}

export function formatText(text: string): string {
    if (typeof text === 'string') {
        text = text.toLowerCase().trim()
        text = text.replace(/ /gi, '_')
        return text
    }
    else {
        return text
    }
}

export function can(permission: string): boolean {
    try {
        const role: any = Retrive({ key: 'role' })
        if (role === false)
            return true
        else if (typeof role === 'object') {
            if (role.permissions.indexOf(formatText(permission).toLowerCase()) >= 0)
                return true
            else
                return false
        }
        else return false
    } catch (error) {
        return false;
    }
}

export function formatDate(date: any): string {
    const day: number = new Date(date).getDate()
    const months: string[] = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]
    const month: string = months[new Date(date).getMonth()]
    const year: number = new Date(date).getFullYear()

    return `${day} ${month} ${year}`
}

export function getAge(date: any): number {
    const year: number = new Date(date).getFullYear()
    const currentYear: number = new Date().getFullYear()
    return currentYear - year
}

export function currentMonth(): string {
    try {
        const months: string[] = []
        months[0] = 'january'
        months[1] = 'february'
        months[2] = 'march'
        months[3] = 'april'
        months[4] = 'may'
        months[5] = 'june'
        months[6] = 'july'
        months[7] = 'augost'
        months[8] = 'september'
        months[9] = 'october'
        months[10] = 'november'
        months[11] = 'december'
        const month: string = months[new Date().getMonth()]
        return month
    } catch (error) {
        return ''
    }
}

export function reFormatText(text: string): string {
    if (typeof (text) === 'string') {
        text = text.toLowerCase().trim()
        text = text.replace(/_/gi, ' ')
        return text
    }
    else {
        return text
    }
}

export function localTime(date: any): string {
    const now: string = new Date(date).toISOString()
    const formatedTime: any = moment(now).fromNow()
    return formatedTime
}

export const shops: shopType[] = [
    {
        name: Translate('electronics and accessories'),
        type: formatText('electronics')
    }

    // {
    //     name: Translate('super market'),
    //     type: formatText('super market')
    // },

    // {
    //     name: Translate('electronics and repair'),
    //     type: formatText('ers')
    // }
]

export const pageNotFound: string = `/page-not-found`

export const pageLimits: number[] = [1, 10, 50, 100, 200, 300, 500, 1000, 5000]

export function getCondition(condition: string): object {
    switch (condition) {
        case 'paid':
            return { branch: Branch('_id'), $expr: { $eq: ["$paid_amount", "$total_amount"] } }
        case 'unpaid':
            return { branch: Branch('_id'), $expr: { $lt: ["$paid_amount", "$total_amount"] } }
        case 'out of stock':
            return { branch: Branch('_id'), stock: { $lte: 0 } }
        case 'almost out of stock':
            return { branch: Branch('_id'), stock: { $gt: 0 }, $expr: { $lte: ["$stock", "$reorder_stock_level"] } }
        case 'in stock':
            return { branch: Branch('_id'), $expr: { $gt: ["$stock", "$reorder_stock_level"] } }
        case 'paid debts':
            return { branch: Branch('_id'), status: 'paid' }
        case 'unpaid debts':
            return { branch: Branch('_id'), status: 'unpaid' }
        case 'sale debts':
            return { branch: Branch('_id'), editable: false }
        case 'phones':
            return { branch: Branch('_id'), type: 'phone' }
        case 'other devices':
            return { branch: Branch('_id'), type: 'other' }
        case 'completed services':
            return { branch: Branch('_id'), status: 'completed' }
        case 'incomplete services':
            return { branch: Branch('_id'), status: 'incomplete' }
        case 'cash sales':
            return { branch: Branch('_id'), status: 'cash' }
        case 'credit sales':
            return { branch: Branch('_id'), status: 'credit' }
        case 'credit then cash sales':
            return { branch: Branch('_id'), status: 'credit_then_cash' }
        case 'shops':
            return { user: User('_id') }
        case 'active shops':
            return { user: User('_id'), days: { $gt: 0 } }
        case 'inactive shops':
            return { user: User('_id'), days: { $lte: 0 } }
        case 'sales':
            return { branch: Branch('_id'), type: 'sale' }
        case 'order sales':
            return { branch: Branch('_id'), status: 'order', type: 'sale' }
        case 'other products':
            return { branch: Branch('_id'), type: { $ne: 'medicine' } }
        case 'medicines':
            return { branch: Branch('_id'), type: 'medicine' }
        case 'products that can expire':
            return { branch: Branch('_id'), expire_date: { $ne: null } }
        case 'expired products':
            return { branch: Branch('_id'), expire_date: { $lte: new Date().toISOString() } }

        default: return { branch: Branch('_id') }
    }
}

export const models: string[] = [
    'branch',
    'user',
    'role',
    'customer',
    'unit',
    'batch',
    'manufacturer',
    'supplier',
    'expense_type',
    'category',
    'expense',
    'debt',
    'debt_history',
    'purchase',
    'product',
    'sale',
    'device',
    'invoice',
    'payment',
    'service',
    'code',
    'webpush',
    'store',
    'store_product',
    'order',
    'activity',
]

export function getMonths(year: number): string[] {
    try {
        const months: string[] = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'augost', 'september', 'october', 'november', 'december']
        const newMonths: string[] = []
        const limit: number = year < new Date().getFullYear() ? 12 : new Date().getMonth() + 1
        for (let index = 0; index < limit; index += 1) {
            newMonths.push(months[index])
        }
        return limit === 12 ? [...months, '6 months', '12 months'] : newMonths
    } catch (error) {
        if (error instanceof Error) {
            Toast(error.message)
        }
        else {
            console.error(error)
        }
        return []
    }
}

export function getYears(): number[] {
    try {
        const years: number[] = []
        for (let year = 2020; year <= new Date().getFullYear(); year += 1) {
            years.push(year)
        }
        return years
    } catch (error) {
        if (error instanceof Error) {
            Toast(error.message)
        }
        else {
            console.error(error)
        }
        return []
    }
}

export function getReportCondition(month: string, year: number): object {
    try {
        let condition: any = { branch: Branch('_id'), year }

        if (month === '6 months') {
            condition = { ...condition, createdAt: { $gte: new Date(`${year}-01-01`).toISOString(), $lt: new Date(`${year}-01-07`).toISOString() } }
        }
        else if (month === '12 months') {
            condition = { ...condition, createdAt: { $gte: new Date(`${year}-01-01`).toISOString(), $lt: new Date(`${year + 1}-01-01`).toISOString() } }
        }
        else {
            condition = { ...condition, month }
        }
        return condition
    } catch (error) {
        if (error instanceof Error) {
            Toast(error.message)
        }
        else {
            console.error(error)
        }
        return { branch: Branch('_id') }
    }
}

export function reports(): { model: string, title: string }[] {
    try {
        let reports: { model: string, title: string }[] = [
            { model: 'sale', title: 'sales report' },
            { model: 'expense', title: 'expenses report' },
            { model: 'purchase', title: 'purchases report' },
            { model: 'customer', title: 'customers report' },
            { model: 'debt', title: 'debts report' },
            { model: 'product', title: 'products report' },
            { model: 'payment', title: 'payments report' },
        ]

        if (Branch('type') === 'electronics') {
            reports.push({ model: 'device', title: 'devices report' }, { model: 'service', title: 'services report' })
        }
        else if (!isUser) {
            reports = [
                { model: 'payment', title: 'payments report' },
                { model: 'branch', title: 'shops report' },
                { model: 'user', title: 'users report' },
                { model: 'sale', title: 'sales report' },
                { model: 'product', title: 'products report' },
                { model: 'customer', title: 'customers report' },
                { model: 'purchase', title: 'purchases report' },
            ]
        }

        return reports
    } catch (error) {
        if (error instanceof Error) {
            Toast(error.message)
        }
        else {
            console.error(error)
        }
        return []
    }
}

export const isUser: boolean = 'user' === User('account_type')
export const isPremium: boolean = 'premium' === Branch('package')

export function listMenu(model: string, id: any): any[] {
    try {
        const conditionKey: string = model === 'user' ? 'created_by' : 'branch'
        const common: any[] = [
            {
                access: `list_purchase`,
                icon: 'local_shipping',
                title: `list purchases`,
                path: '/purchases',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_product`,
                icon: 'inventory',
                title: `list products created`,
                path: '/products',
                condition: { propsCondition: { [conditionKey]: id } }
            },

            {
                access: `list_activity`,
                icon: 'shop',
                title: `list activities`,
                path: '/shop/activities',
                condition: { propsCondition: { [model]: id } }
            },
            {
                access: `list_expense`,
                icon: 'money',
                title: `list expenses`,
                path: '/expenses',
                condition: { propsCondition: { [conditionKey]: id } }
            },

            {
                access: `list_customer`,
                icon: 'person',
                title: `list customers`,
                path: '/customers',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_sale`,
                icon: 'shopping_cart',
                title: `list sales`,
                path: '/sales',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_role`,
                icon: 'task',
                title: `list roles`,
                path: '/roles',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_debt`,
                icon: 'price_check',
                title: `list debts`,
                path: '/debts',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_store`,
                icon: 'store',
                title: `list stores`,
                path: '/stores',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_manufacturer`,
                icon: 'business',
                title: `list manufacturer`,
                path: '/manufacturer',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_supplier`,
                icon: 'local_convenience_store',
                title: `list suppliers`,
                path: '/suppliers',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_category`,
                icon: 'spa',
                title: `list categories`,
                path: '/categories',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_unit`,
                icon: 'qr_code',
                title: `list units`,
                path: '/units',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_batch`,
                icon: 'pin',
                title: `list batch`,
                path: '/batch',
                condition: { propsCondition: { [conditionKey]: id } }
            },
            {
                access: `list_user`,
                icon: 'groups',
                title: `list users`,
                path: '/users',
                condition: { propsCondition: { [conditionKey]: id } }
            },
        ].sort((a: any, b: any) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
        return common

    } catch (error) {
        if (error instanceof Error) {
            Toast(error.message)
        }
        else {
            console.error(error)
        }
        return []
    }
}



export function formatTin(tin: string): string {
    try {
        return `${tin.substring(0, 3)}-${tin.substring(3, 6)}-${tin.substring(6, 9)}`
    } catch (error) {
        Toast(error.message)
        return tin
    }
}

export const visible: boolean = true