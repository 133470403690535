import styled from "styled-components";

const StyledSection: any = styled.div`
  color: ${(p: any) => p.theme.colorLabel};
  margin-bottom: ${(p: any) => p.theme.lenSm3};
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
`;

const SettingsSubheader: any = ({ children }: any) => {
    return <StyledSection>{children}</StyledSection>;
};

export default SettingsSubheader;
