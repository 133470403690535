import styled from "styled-components";
import useStyledTheme from "../../hooks/useStyledTheme";

const StyledWrapper: any = styled.div`
margin-bottom: ${(p) => p.theme.lenMd1};`;

const StyledFontLabel: any = styled.label`
  display: flex;
  align-items: center;
  height: ${(p: any) => p.theme.lenLg1};
  cursor: pointer;
  & > input {
    position: absolute;
    left: -9999px;
  }
`;

const StyledFontText: any = styled.span`
  color: ${(p: any) => (p.isActive ? p.theme.colorText : p.theme.colorLabel)};
  margin-left: ${(p) => p.theme.lenSm2};
  font-size: 14px;
  font-weight: 300;
`;

const StyledRadioButton: any = styled.span`
  position: relative;
  width: ${(p: any) => p.theme.lenMd1};
  height: ${(p: any) => p.theme.lenMd1};
  border-radius: 50%;
  border: 2px solid
    ${(p: any) => (p.isActive ? p.theme.colorPrimary : p.theme.colorBorder)};

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${(p: any) => p.theme.lenSm2};
    height: ${(p: any) => p.theme.lenSm2};
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(${(p: any) => (p.isActive ? 1 : 0)});
    background: ${(p: any) => p.theme.colorPrimary};
    transition: transform 0.25s;
  }
`;

const fonts: string[] = [
  "Glory",
  "Roboto",
  "Helvetica Neue",
  "PT Sans",
  "Gotham",
  "Segoe UI",
  "Single Day",
  "Lato",
  "Comic Neue",
  "Product Sans",
];

const SettingsFontPicker: any = ({ setTheme }: any) => {
    const theme: any = useStyledTheme();
    const isActive = (font: any) => font === theme.fontFamily;

    return (
        <StyledWrapper>
            {fonts.map((font: any) => (
                <StyledFontLabel
                    key={font}
                    htmlFor={font}
                    onChange={() => setTheme(theme, font, theme.language)}
                >
                    <input type="radio" id={font} name="font" />
                    <StyledRadioButton isActive={isActive(font)}></StyledRadioButton>
                    <StyledFontText isActive={isActive(font)}>{font}</StyledFontText>
                </StyledFontLabel>
            ))}
        </StyledWrapper>
    );
};

export default SettingsFontPicker;
