import { Branch, can, isPremium, isUser } from "../helpers";
import { menu } from "../types";

const menus: menu[] = [
    {
        name: 'dashboard',
        icon: 'dashboard',
        hasSubMenus: false,
        link: '/dashboard',
        visible: can('view_dashboard_menu')
    },

    {
        name: 'product',
        icon: 'inventory',
        hasSubMenus: true,
        link: '#',
        visible: (can('view_product_menu') || can('create_product') || can('list_product') || can('create_category') || can('list_category') || can('create_batch') || can('list_batch') || can('create_unit') || can('list_unit')) && (isUser),
        subMenus: [
            {
                name: 'add new  product',
                link: '/product/form',
                visible: can('create_product'),
            },
            {
                name: 'list products',
                link: '/products',
                visible: can('list_product'),
            },
            {
                name: 'stock taking',
                link: '/product/stock-taking',
                visible: can('list_product'),
            },
            {
                name: 'add new  product category',
                link: '/category/form',
                visible: can('create_category'),
            },
            {
                name: 'list product category',
                link: '/categories',
                visible: can('list_category'),
            },
            {
                name: 'add new  product batch',
                link: '/batch/form',
                visible: can('create_batch')
            },
            {
                name: 'list product batch',
                link: '/batch',
                visible: can('list batch')
            },
            {
                name: 'add new  product unit',
                link: '/unit/form',
                visible: can('create unit')
            },
            {
                name: 'list product unit',
                link: '/units',
                visible: can('list unit')
            }
        ]
    },
    // #POINT OF SALE
    {
        name: 'point of sale',
        icon: 'shopping_cart',
        hasSubMenus: true,
        link: '#',
        visible: (can('create sale') || can('list sale')) && (isUser),
        subMenus: [
            {
                name: 'make sales',
                link: '/sale/form',
                visible: can('create sale')
            },
            {
                name: 'list sales',
                link: '/sales',
                visible: can('list sale')
            },
            {
                name: 'day sales',
                link: '/day-sales',
                visible: can('list sale')
            }
        ]
    },
    // #ORDER MENU
    {
        name: 'order',
        icon: 'local_offer',
        hasSubMenus: true,
        link: '#',
        visible: (can('create order') || can('list order')) && (isUser) && (isPremium),
        subMenus: [
            {
                name: 'add new  order',
                link: '/order/form',
                visible: can('create order')
            },
            {
                name: 'list orders',
                link: '/orders',
                visible: can('list order')
            }
        ]
    },
    // #PROFORMA INVOICE MENU
    {
        name: 'proforma invoice',
        icon: 'receipt_long',
        hasSubMenus: true,
        link: '#',
        visible: (can('create proforma invoice') || can('list proforma invoice')) && (isUser) && (isPremium),
        subMenus: [
            {
                name: 'add new  profoma invoice',
                link: '/proforma-invoice/form',
                visible: can('create proforma invoice')
            },
            {
                name: 'list proforma invoices',
                link: '/proforma-invoices',
                visible: can('list proforma invoice')
            }
        ]
    },
    {
        name: 'expense',
        icon: 'money',
        hasSubMenus: true,
        link: '#',
        visible: (can('create expense') || can('list expense') || can('create expense type') || can('list expense type')) && (isUser),
        subMenus: [
            {
                name: 'add new  expense',
                link: '/expense/form',
                visible: can('create expense')
            },
            {
                name: 'list expenses',
                link: '/expenses',
                visible: can('list expense')
            },
            {
                name: 'add new  expense type',
                link: '/expense-type/form',
                visible: can('create expense type')
            },
            {
                name: 'list expense types',
                link: '/expense-types',
                visible: can('list expense type')
            }
        ]
    },
    {
        name: 'purchase',
        icon: 'local_shipping',
        hasSubMenus: true,
        link: '#',
        visible: ((can('create purchase') || can('list purchase')) && (isUser)),
        subMenus: [
            {
                name: 'add new  purchase',
                link: '/purchase/form',
                visible: can('create purchase'),
            },
            {
                name: 'list purchases',
                link: '/purchases',
                visible: can('list purchase'),
            }
        ]
    },
    {
        name: 'manufacturer',
        icon: 'factory',
        hasSubMenus: true,
        link: '#',
        visible: ((can('create manufacturer') || can('list manufacturer'))) && (isUser),
        subMenus: [
            {
                name: 'add new  manufacturer',
                link: '/manufacturer/form',
                visible: can('create manufacturer'),
            },
            {
                name: 'list manufacturer',
                link: '/manufacturer',
                visible: can('list manufacturer'),
            }
        ]
    },

    {
        name: 'supplier',
        icon: 'precision_manufacturing',
        hasSubMenus: true,
        link: '#',
        visible: ((can('create supplier') || can('list supplier'))) && (isUser),
        subMenus: [
            {
                name: 'add new  supplier',
                link: '/supplier/form',
                visible: can('create supplier'),
            },
            {
                name: 'list supplier',
                link: '/suppliers',
                visible: can('list supplier'),
            }
        ]
    },

    {
        name: 'devices',
        icon: 'devices',
        hasSubMenus: true,
        link: '#',
        visible: ((Branch('type') === "electronics") && (can('create device') || can('list device'))) && (isUser),
        subMenus: [
            {
                name: 'add new  device',
                link: '/device/form',
                visible: can('create device'),
            },
            {
                name: 'list devices',
                link: '/devices',
                visible: can('list device'),
            }
        ]
    },
    {
        name: 'services',
        icon: 'build',
        hasSubMenus: true,
        link: '#',
        visible: (Branch('type') === "electronics") && (can('create service') || can('list service')) && (isUser),
        subMenus: [
            {
                name: 'add new  service',
                link: '/service/form',
                visible: can('create service'),
            },
            {
                name: 'list services',
                link: '/services',
                visible: can('list service'),
            }
        ]
    },
    {
        name: 'customers',
        icon: 'person',
        hasSubMenus: true,
        link: '#',
        visible: (can('create customer') || can('list customer')) && (isUser),
        subMenus: [
            {
                name: 'add new  customer',
                link: '/customer/form',
                visible: can('create customer'),
            },
            {
                name: 'list customers',
                link: '/customers',
                visible: can('list customer'),
            }
        ]
    },
    // #DEBT MENU
    {
        name: 'debts',
        icon: 'price_check',
        hasSubMenus: true,
        link: '#',
        visible: (can('create debt') || can('list debt')) && (isUser),
        subMenus: [
            {
                name: 'add new debt',
                link: '/debt/form',
                visible: can('create debt'),
            },
            {
                name: 'list debts',
                link: '/debts',
                visible: can('list debt'),
            }
        ]
    },
    {
        name: 'message',
        icon: 'mail',
        hasSubMenus: false,
        link: '/message/form',
        visible: (can('create message') && (isPremium)) || !isUser
    },
    {
        name: 'report',
        icon: 'receipt',
        hasSubMenus: true,
        link: '#',
        visible: can('create_report') || can('view report'),
        subMenus: [
            {
                name: 'add new  report',
                link: '/report/form',
                visible: can('create report'),
            },
            {
                name: 'income statement',
                link: '/report/income-statement',
                visible: can('view report') && (isUser),
            },
            {
                name: 'statistics',
                link: '/report/statistics',
                visible: can('view report'),
            }
        ]
    },
    {
        name: 'roles',
        icon: 'task',
        hasSubMenus: true,
        link: '#',
        visible: (can('create role') || can('list role')) && (isUser),
        subMenus: [
            {
                name: 'add new  user role',
                link: '/role/form',
                visible: can('create_role'),
            },
            {
                name: 'list user roles',
                link: '/roles',
                visible: can('list_role'),
            }
        ]
    },
    {
        name: 'system users',
        icon: 'groups',
        hasSubMenus: isUser ? true : false,
        link: isUser ? '#' : '/users',
        visible: (can('create_user') || can('list_user')),
        subMenus: [
            {
                name: 'add new  shop user',
                link: '/user/form',
                visible: can('create_user') && (isUser),
            },
            {
                name: 'list shop users',
                link: '/users',
                visible: can('list_user'),
            },
        ]
    },
    {
        name: 'shop',
        icon: 'shop',
        hasSubMenus: true,
        link: '#',
        visible: can('create_shop') || can('list_shop') || can('list_activity') || can('list_payment'),
        subMenus: [
            {
                name: 'add new  shop',
                link: '/shop/form',
                visible: can('create_shop') && (isUser),
            },
            {
                name: 'list shops',
                link: '/shops',
                visible: can('list_shop'),
            },
            {
                name: 'list payments',
                link: '/payments',
                visible: can('list_payment'),
            },
            // {
            //     name: 'list activities',
            //     link: '/shop/activities',
            //     visible: can('list_activity'),
            // }
        ]
    },
    {
        name: 'store management',
        icon: 'store',
        hasSubMenus: true,
        link: '#',
        visible: ((can('create store') || can('list store')) && (isUser)) && (isPremium),
        subMenus: [
            {
                name: 'add new  store',
                link: '/store/form',
                visible: can('create store'),
            },
            {
                name: 'list stores',
                link: '/stores',
                visible: can('list store'),
            }
        ]
    },
    {
        name: 'settings',
        icon: 'settings',
        hasSubMenus: true,
        link: '#',
        visible: true,
        subMenus: [
            {
                name: 'view profile',
                link: '/settings/view-profile',
                visible: true,
            },
            {
                name: 'edit profile',
                link: '/settings/edit-profile',
                visible: true,
            },
            {
                name: 'change password',
                link: '/settings/change-password',
                visible: true,
            }
        ]
    },
]

export default menus