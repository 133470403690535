import styled, { css } from "styled-components";
import { rgba } from "polished";

const visible = css`
  visibility: visible;
  opacity: 1;
`;

const StyledWrapper: any = styled.div`
  position: ${(props: any) => props.position || "fixed"};
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background: ${rgba("black", 0.5)};
  transition-property: visibility, opacity;
  transition-duration: 0.5s;
  cursor: pointer;

  ${(props: any) => props.isVisible && visible};
`;

const Overlay: any = ({ isVisible, position, zIndex, ...rest }: any) => {
    return (
        <StyledWrapper
            isVisible={isVisible}
            position={position}
            zIndex={zIndex}
            {...rest}
        />
    );
};

export default Overlay;
