import { lazy } from "react"
import { route } from "../types"

const CustomerForm = lazy(() => import("../screens/customer/customerForm"))
const CustomerList = lazy(() => import("../screens/customer/customerList"))
const CustomerView = lazy(() => import("../screens/customer/customerView"))
const ExpenseForm = lazy(() => import("../screens/expense/expenseForm"))
const ExpenseList = lazy(() => import("../screens/expense/expenseList"))
const ExpenseView = lazy(() => import("../screens/expense/expenseView"))
const ExpenseTypeForm = lazy(() => import("../screens/expenseType/expenseTypeForm"))
const ExpenseType = lazy(() => import("../screens/expenseType/expenseTypeList"))
const ExpenseTypeView = lazy(() => import("../screens/expenseType/expeseTypeView"))
const PageNotFound = lazy(() => import("../screens/pages/404"))
const RoleForm = lazy(() => import("../screens/role/roleForm"))
const RoleList = lazy(() => import("../screens/role/roleList"))
const RoleView = lazy(() => import("../screens/role/roleView"))
const ChangePassword = lazy(() => import("../screens/user/changePassword"))
const UserList = lazy(() => import("../screens/user/userList"))
const UserForm = lazy(() => import("../screens/user/userForm"))
const UserView = lazy(() => import("../screens/user/userView"))
const ShopForm = lazy(() => import("../screens/shop/shopForm"))
const ShopList = lazy(() => import("../screens/shop/shopList"))
const ShopView = lazy(() => import("../screens/shop/shopView"))
const CategoryForm = lazy(() => import("../screens/category/categoryForm"))
const CategoryList = lazy(() => import("../screens/category/categoryList"))
const CategoryView = lazy(() => import("../screens/category/categoryView"))
const BatchView = lazy(() => import("../screens/batch/batchView"))
const BatchList = lazy(() => import("../screens/batch/batchList"))
const BatchForm = lazy(() => import("../screens/batch/batchForm"))
const ProductForm = lazy(() => import("../screens/product/productForm"))
const SupplierForm = lazy(() => import("../screens/supplier/supplierForm"))
const SupplierList = lazy(() => import("../screens/supplier/supplierList"))
const SupplierView = lazy(() => import("../screens/supplier/supplierView"))
const ManufacturerForm = lazy(() => import("../screens/manufacturer/manufacturerForm"))
const ManufacturerList = lazy(() => import("../screens/manufacturer/manufacturerList"))
const ManufacturerView = lazy(() => import("../screens/manufacturer/manufacturerView"))
const UnitForm = lazy(() => import("../screens/unit/unitForm"))
const UnitList = lazy(() => import("../screens/unit/unitList"))
const UnitView = lazy(() => import("../screens/unit/unitView"))
const ProductList = lazy(() => import("../screens/product/productList"))
const ProductView = lazy(() => import("../screens/product/productView"))
const PurchaseForm = lazy(() => import("../screens/purchase/purchaseForm"))
const PurchaseList = lazy(() => import("../screens/purchase/purchaseList"))
const PurchaseView = lazy(() => import("../screens/purchase/purchaseView"))
const DebtForm = lazy(() => import("../screens/debt/debtForm"))
const DebtList = lazy(() => import("../screens/debt/debtList"))
const DebtView = lazy(() => import("../screens/debt/debtView"))
const DebtHistoryForm = lazy(() => import("../screens/debt/debtHistoryForm"))
const DeviceForm = lazy(() => import("../screens/device/deviceForm"))
const DeviceList = lazy(() => import("../screens/device/deviceList"))
const DeviceView = lazy(() => import("../screens/device/deviceView"))
const ServiceForm = lazy(() => import("../screens/service/serviceForm"))
const ServiceList = lazy(() => import("../screens/service/serviceList"))
const ServiceView = lazy(() => import("../screens/service/serviceView"))
const MessageForm = lazy(() => import("../screens/message/messageForm"))
const SaleForm = lazy(() => import("../screens/sale/saleForm"))
const SaleList = lazy(() => import("../screens/sale/saleList"))
const SaleView = lazy(() => import("../screens/sale/saleView"))
const StoreForm = lazy(() => import("../screens/store/storeForm"))
const StoreList = lazy(() => import("../screens/store/storeList"))
const StoreView = lazy(() => import("../screens/store/storeView"))
const StoreProductForm = lazy(() => import("../screens/storeProduct/storeProductForm"))
const StoreProductList = lazy(() => import("../screens/storeProduct/storeProductList"))
const StoreProductView = lazy(() => import("../screens/storeProduct/storeProductView"))
const StoreProductStock = lazy(() => import("../screens/storeProduct/storeProductStock"))
const ProductStock = lazy(() => import("../screens/product/productStock"))
const OrderList = lazy(() => import("../screens/order/orderList"))
const OrderView = lazy(() => import("../screens/order/orderView"))
const StockTaking = lazy(() => import("../screens/product/stockTaking"))
const ActivityList = lazy(() => import("../screens/activity/activityList"))
const IncomeStatement = lazy(() => import("../screens/report/incomeStatement"))
const ReportForm = lazy(() => import("../screens/report/reportForm"))

const routes: route[] = [
    {
        name: 'expense_type_form',
        path: '/expense-type/form',
        component: ExpenseTypeForm
    },

    {
        name: 'list_expense_types',
        path: '/expense-types',
        component: ExpenseType
    },

    {
        name: 'view_expense_type',
        path: '/expense-type/view',
        component: ExpenseTypeView
    },

    // roles routes
    {
        name: 'role_form',
        path: '/role/form',
        component: RoleForm,
    },

    {
        name: 'role_list',
        path: '/roles',
        component: RoleList,
    },

    {
        name: 'role_view',
        path: '/role/view',
        component: RoleView,
    },

    // #USER ROUTES
    {
        name: 'user_form',
        path: '/user/form',
        component: UserForm,
    },

    {
        name: 'user_view',
        path: '/user/view',
        component: UserView,
    },

    {
        name: 'user_list',
        path: '/users',
        component: UserList,
    },

    {
        name: 'user_view_profile',
        path: '/settings/view-profile',
        component: UserView,
    },

    {
        name: 'user_change_password',
        path: '/settings/change-password',
        component: ChangePassword,
    },
    {
        name: 'edit-profile',
        path: '/settings/edit-profile',
        component: UserForm,
    },

    // #EXPENSE ROUTES
    {
        name: 'expense_form',
        path: '/expense/form',
        component: ExpenseForm
    },
    {
        name: 'list_expenses',
        path: '/expenses',
        component: ExpenseList
    },
    {
        name: 'view_expense',
        path: '/expense/view',
        component: ExpenseView
    },

    // #CUSTOMER ROUTES
    {
        name: 'customer_form',
        path: '/customer/form',
        component: CustomerForm,
    },
    {
        name: 'list_customer',
        path: '/customers',
        component: CustomerList
    },
    {
        name: 'view_customer',
        path: '/customer/view',
        component: CustomerView
    },

    // #SHOP ROUTES
    {
        name: 'shop_form',
        path: '/shop/form',
        component: ShopForm,
    },
    {
        name: 'list_shop',
        path: '/shops',
        component: ShopList
    },
    {
        name: 'view_shop',
        path: '/shop/view',
        component: ShopView
    },
    {
        name: 'shop_activities',
        path: '/shop/activities',
        component: ActivityList
    },

    // #CATEGORY ROUTES
    {
        name: 'category_form',
        path: '/category/form',
        component: CategoryForm,
    },
    {
        name: 'list_category',
        path: '/categories',
        component: CategoryList
    },
    {
        name: 'view_category',
        path: '/category/view',
        component: CategoryView
    },

    // #BATCH ROUTES
    {
        name: 'batch_form',
        path: '/batch/form',
        component: BatchForm,
    },
    {
        name: 'list_batch',
        path: '/batch',
        component: BatchList
    },
    {
        name: 'view_batch',
        path: '/batch/view',
        component: BatchView
    },

    // #UNIT ROUTES
    {
        name: 'unit_form',
        path: '/unit/form',
        component: UnitForm,
    },
    {
        name: 'list_unit',
        path: '/units',
        component: UnitList
    },
    {
        name: 'view_unit',
        path: '/unit/view',
        component: UnitView
    },

    // #PRODUCT ROUTES
    {
        name: 'product_form',
        path: '/product/form',
        component: ProductForm,
    },
    {
        name: 'list_product',
        path: '/products',
        component: ProductList
    },

    {
        name: 'view_product',
        path: '/product/view',
        component: ProductView
    },
    {
        name: 'stock_product',
        path: '/product/stock',
        component: ProductStock
    },
    {
        name: 'stock_taking',
        path: '/product/stock-taking',
        component: StockTaking
    },

    // #SUPPLIER ROUTES
    {
        name: 'supplier_form',
        path: '/supplier/form',
        component: SupplierForm,
    },
    {
        name: 'list_supplier',
        path: '/suppliers',
        component: SupplierList
    },
    {
        name: 'view_supplier',
        path: '/supplier/view',
        component: SupplierView
    },

    // #MANUFACTURER ROUTES
    {
        name: 'manufacturer_form',
        path: '/manufacturer/form',
        component: ManufacturerForm,
    },
    {
        name: 'list_manufacturer',
        path: '/manufacturer',
        component: ManufacturerList
    },
    {
        name: 'view_manufacturer',
        path: '/manufacturer/view',
        component: ManufacturerView
    },

    // #PURCHASE ROUTES
    {
        name: 'purchase_form',
        path: '/purchase/form',
        component: PurchaseForm,
    },
    {
        name: 'list_purchase',
        path: '/purchases',
        component: PurchaseList
    },
    {
        name: 'view_purchase',
        path: '/purchase/view',
        component: PurchaseView
    },

    // #DEBT ROUTES
    {
        name: 'debt_form',
        path: '/debt/form',
        component: DebtForm,
    },
    {
        name: 'list_debt',
        path: '/debts',
        component: DebtList
    },
    {
        name: 'view_debt',
        path: '/debt/view',
        component: DebtView
    },

    // #DEBT HISTORY ROUTES

    {
        name: 'debt_history_form',
        path: '/debt-history/form',
        component: DebtHistoryForm,
    },

    // #DEVICE ROUTES
    {
        name: 'device_form',
        path: '/device/form',
        component: DeviceForm,
    },
    {
        name: 'list_device',
        path: '/devices',
        component: DeviceList
    },
    {
        name: 'view_device',
        path: '/device/view',
        component: DeviceView
    },

    // #SERVICE ROUTES
    {
        name: 'service_form',
        path: '/service/form',
        component: ServiceForm,
    },

    {
        name: 'list_service',
        path: '/services',
        component: ServiceList
    },
    {
        name: 'view_service',
        path: '/service/view',
        component: ServiceView
    },

    // #MESSAGE ROUTES
    {
        name: 'message_form',
        path: '/message/form',
        component: MessageForm,
    },

    // #SALE ROUTES
    {
        name: 'sale_form',
        path: '/sale/form',
        component: SaleForm
    },
    {
        name: 'sale_list',
        path: '/sales',
        component: SaleList
    },
    {
        name: 'sale_view',
        path: '/sale/view',
        component: SaleView
    },
    {
        name: 'day sale',
        path: '/day-sales',
        component: lazy(() => import('../screens/sale/daySales'))
    },
    // #STORE ROUTES
    {
        name: 'store_form',
        path: '/store/form',
        component: StoreForm
    },
    {
        name: 'store_list',
        path: '/stores',
        component: StoreList
    },
    {
        name: 'store_view',
        path: '/store/view',
        component: StoreView
    },

    // #STORE PRODUCT ROUTES
    {
        name: 'store_product_form',
        path: '/store-product/form',
        component: StoreProductForm
    },
    {
        name: 'store_product_list',
        path: '/store-products',
        component: StoreProductList
    },
    {
        name: 'store_product_view',
        path: '/store-product/view',
        component: StoreProductView
    },
    {
        name: 'store_product_stock',
        path: '/store-product/stock',
        component: StoreProductStock
    },

    // #ORDER ROUTES
    {
        name: 'order_form',
        path: '/order/form',
        component: SaleForm
    },
    {
        name: 'order_list',
        path: '/orders',
        component: OrderList
    },
    {
        name: 'order_view',
        path: '/order/view',
        component: OrderView
    },

    // #PROFOMA INVOICE ROUTES
    {
        name: 'profoma_invoice',
        path: '/proforma-invoice/form',
        component: SaleForm
    },
    {
        name: 'proforma_invoice_list',
        path: '/proforma-invoices',
        component: OrderList
    },
    {
        name: 'proforma_invoice_view',
        path: '/proforma-invoice/view',
        component: OrderView
    },

    // #REPORT ROUTES
    {
        name: 'income_statement',
        path: '/report/income-statement',
        component: IncomeStatement
    },
    {
        name: 'report_form',
        path: '/report/form',
        component: ReportForm
    },
    {
        name: 'report_statistics',
        path: '/report/statistics',
        component: lazy(() => import('../screens/report/statistics'))
    },

    // #PAYMENT ROUTES
    {
        name: 'payment_create',
        path: '/payment/form',
        component: lazy(() => import('../screens/payment/paymentForm'))
    },
    {
        name: 'payment_list',
        path: '/payments',
        component: lazy(() => import('../screens/payment/paymentList'))
    },
    {
        name: 'payment_view',
        path: '/payment/view',
        component: lazy(() => import('../screens/payment/paymentView'))
    },

    // #DASHBOARD ROUTE
    {
        name: 'dashboard',
        path: '/dashboard',
        component: lazy(() => import('../screens/dashboard/dashboard'))
    },
    {
        name: 'dashboard',
        path: '/',
        component: lazy(() => import('../screens/dashboard/dashboard'))
    },

    // # DEVICE ROUTE
    {
        name: 'device_form',
        path: '/device/form',
        component: lazy(() => import('../screens/device/deviceForm'))
    },
    {
        name: 'device_view',
        path: '/device/view',
        component: lazy(() => import('../screens/device/deviceView'))
    },

    {
        name: 'device_list',
        path: '/devices',
        component: lazy(() => import('../screens/device/deviceList'))
    },

    // # SERVICE ROUTE
    {
        name: 'service_form',
        path: '/service/form',
        component: lazy(() => import('../screens/service/serviceForm'))
    },
    {
        name: 'service_view',
        path: '/service/view',
        component: lazy(() => import('../screens/service/serviceView'))
    },

    {
        name: 'service_list',
        path: '/services',
        component: lazy(() => import('../screens/service/serviceList'))
    },

    // # 404 ROUTE SHOULD ALWAYS BE THE LAST ROUTE
    {
        name: 'page not found',
        path: '*',
        component: PageNotFound
    },
]

export default routes