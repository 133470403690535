import { rgba } from 'polished'
import { defaultTheme } from '../types'

const dTheme: defaultTheme = {
    gutterWidth: "0.5rem",
    containerMaxWidthXs: "100%",
    containerMaxWidthSm: "100%",
    containerMaxWidthMd: "100%",
    containerMaxWidthLg: "1080px",
    containerMaxWidthXl: "1200px",
    screenWidthXs: "576px",
    screenWidthSm: "768px",
    screenWidthMd: "992px",
    screenWidthLg: "1200px",
    screenWidthXl: "1920px",
    sidebarWidth: "56px",
    sidebarWidthMd: "220px",
    colorForDark1: rgba("white", 0.96),
    colorForDark2: rgba("white", 0.65),
    colorForDark3: rgba("white", 0.45),
    fontFamily: "Product Sans",
    fontWeightBold: 600,
    fontWeightSemibold: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    heightCardMd: "360px",
    heightChartMd: "150px",
    lenSm1: "0.25rem",
    lenSm2: "0.5rem",
    lenSm3: "0.75rem",
    lenMd1: "1rem",
    lenMd2: "1.25rem",
    lenMd3: "1.5rem",
    lenLg1: "2rem",
    lenLg2: "3rem",
    lenLg3: "4rem",
    lenXl1: "5rem",
    lenXl2: "6rem",
    lenXl3: "7rem",
    language: 'English',
    isNavbarOpen: false
}

export default dTheme as defaultTheme