import { Toast } from '../components/toast'
import { get, post, reducer, response } from '../types'
import { Encrypt } from './encryption'
import { Branch, models, Url } from './index'

const branch: any = Branch('_id')
// const user: any = User('_id')
const headers = {
    "Content-Type": "application/json",
    "Authorization": "IjE4ODIzMTgxJDJhJDEwJFNXY1dOQ0dsN2ttVjhMSGN5L1QwUE9BbFBzRC82MlVnZ3YzTmVpcE1GeDdaeEt2TlBGdkNDZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5MjUyMjY0NyI=",
    "branch": branch ? Encrypt(branch) : "IjE4ODIzMTgxJDJhJDEwJFNXY1dOQ0dsN2ttVjhMSGN5L1QwUE9BbFBzRC82MlVnZ3YzTmVpcE1GeDdaeEt2TlBGdkNDZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5MjUyMjY0NyI="
}

class api {

    // PAGINATION 
    private pagination(pages: number, page: number, listPerPage: number, reducer: reducer): void {
        try {
            if (listPerPage) {
                const remainData: number = pages - listPerPage
                if (remainData > 0) {
                    const remainPages: number = Math.ceil(remainData / listPerPage)
                    const pagination: number[] = []

                    for (let i = page; i <= remainPages + 1; i += 1) {
                        pagination.push(i)
                    }
                    reducer.dispatch({ type: 'pagination', value: { pagination } })
                }
            }

        } catch (error) {
            if (error instanceof Error)
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
            else
                console.error(error)
        }
    }

    // GET
    public async get(options: get, reducer: reducer): Promise<response> {
        try {
            // activate loader
            reducer.dispatch({ type: 'loading', value: { loading: true } })

            // add loading message
            reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: `fetching ${options.type}` } })

            // send api request to the server
            let response: any = await fetch(`${Url()}/${options.route}?${options.parameters}`, { headers })
            response = await response.json()

            if (response.success) {
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: '' } })
                reducer.dispatch({ type: "loading", value: { loading: false } })
                if (options.route === 'list') {
                    reducer.dispatch({ type: 'pages', value: { pages: response.message.pages } })
                    reducer.dispatch({ type: 'page', value: { page: response.message.next - 1 > 0 ? (response.message.next - 1) : (response.message.next - 1) < 0 ? response.message.previous + 1 : 0 } })
                    reducer.dispatch({ type: 'next', value: { next: response.message.next } })
                    reducer.dispatch({ type: 'previous', value: { previous: response.message.previous } })
                    reducer.dispatch({ type: 'sort', value: { sort: options.sort } })
                    reducer.dispatch({ type: 'condition', value: { condition: options.condition } })
                    reducer.dispatch({ type: 'order', value: { order: options.order } })
                    this.pagination(response.message.pages, (response.message.next - 1) > 0 ? (response.message.next - 1) : (response.message.next - 1) < 0 ? response.message.previous + 1 : 1, reducer.state.limit ? reducer.state.limit : 100, reducer)
                    reducer.dispatch({ type: options.type, value: { [options.type]: response.message.list } })
                    return { success: true, message: response.message }
                }
                else if (options.route === 'list-all' || options.route === 'api/search') {
                    reducer.dispatch({ type: options.type, value: { [options.type]: response.message } })
                    reducer.dispatch({ type: 'sort', value: { sort: options.sort } })
                    reducer.dispatch({ type: 'condition', value: { condition: options.condition } })
                    reducer.dispatch({ type: 'order', value: { order: options.order } })
                    return { success: true, message: response.message }
                }
                else {
                    reducer.dispatch({ type: options.type, value: { [options.type]: response.message } })
                    return { success: true, message: response.message }
                }
            }
            else {
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: response.message } })
                reducer.dispatch({ type: "loading", value: { loading: false } })
                if (options.route === 'list' || options.route === 'list-all' || options.route === 'search') {
                    reducer.dispatch({ type: 'sort', value: { sort: options.sort } })
                    reducer.dispatch({ type: 'condition', value: { condition: options.condition } })
                    reducer.dispatch({ type: 'order', value: { order: options.order } })
                    reducer.dispatch({ type: options.type, value: { [options.type]: [] } })
                }
                else
                    reducer.dispatch({ type: options.type, value: { [options.type]: null } })
                return { success: false, message: response.message }
            }

        } catch (error) {
            reducer.dispatch({ type: "loading", value: { loading: false } })
            if (error instanceof Error) {
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
                return { success: false, message: error.message }
            }
            else {
                console.error(error)
                return { success: false, message: error }
            }
        }
    }

    // POST 
    public async post(options: post, reducer: reducer): Promise<response> {
        try {
            reducer.dispatch({ type: 'loading', value: { loading: true } })
            reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: `Creating new ${options.type}` } })

            let response: any = await fetch(`${Url()}/${options.route}`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(options.body),
                headers
            })
            response = await response.json()

            if (response.success) {
                reducer.dispatch({ type: 'loading', value: { loading: false } })
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: `new ${options.type} has been created` } })
                // reducer.dispatch({ type: options.type, value: { [options.type]: response.message } })
                this.createActivity(options.body.schema, response.message, 'create')
                return { success: true, message: response.message }
            }
            else {
                reducer.dispatch({ type: 'loading', value: { loading: false } })
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: response.message } })
                // reducer.dispatch({ type: options.type, value: { [options.type]: null } })
                return { success: false, message: response.message }
            }

        } catch (error) {
            reducer.dispatch({ type: 'loading', value: { loading: false } })
            if (error instanceof Error) {
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
                return { success: false, message: error.message }
            }
            else {
                console.error(error)
                return { success: false, message: error }
            }
        }
    }

    // PUT
    public async put(options: post, reducer: reducer): Promise<response> {
        try {
            reducer.dispatch({ type: 'loading', value: { loading: true } })
            reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: `Updating ${options.type}` } })

            let response: any = await fetch(`${Url()}/${options.route}`, {
                method: 'PUT',
                mode: 'cors',
                body: JSON.stringify(options.body),
                headers
            })
            response = await response.json()

            if (response.success) {
                reducer.dispatch({ type: 'loading', value: { loading: false } })
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: `${options.type} has been updated` } })
                // reducer.dispatch({ type: options.type, value: { [options.type]: response.message } })
                this.createActivity(options.body.schema, response.message, 'update')
                return { success: true, message: response.message }
            }
            else {
                reducer.dispatch({ type: 'loading', value: { loading: false } })
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: response.message } })
                // reducer.dispatch({ type: options.type, value: { [options.type]: null } })
                return { success: false, message: response.message }
            }

        } catch (error) {
            reducer.dispatch({ type: 'loading', value: { loading: false } })
            if (error instanceof Error) {
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
                return { success: false, message: error.message }
            }
            else {
                console.error(error)
                return { success: false, message: error }
            }
        }
    }

    // DELETE
    public async delete(options: get, reducer: reducer): Promise<response> {
        try {
            reducer.dispatch({ type: 'loading', value: { loading: true } })
            reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: `Deleting ${options.type}` } })

            let response: any = await fetch(`${Url()}/${options.route}?${options.parameters}`, {
                method: 'DELETE',
                mode: 'cors',
                headers
            })
            response = await response.json()

            if (response.success) {
                let oldList: any[] = reducer.state[options.type]
                if (oldList?.length > 0) {
                    const newList: any[] = oldList?.filter((list: any) => list._id !== response.message._id)
                    reducer.dispatch({ type: options.type, value: { [options.type]: newList } })
                }

                let modelName: string = ''
                for (let model of models) {
                    if (options.parameters.split('&').includes(`schema=${model}`)) {
                        modelName = model
                    }
                }
                this.createActivity(modelName, response.message, 'delete')
                reducer.dispatch({ type: 'loading', value: { loading: false } })
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: `${options.type} has been deleted` } })
                return { success: true, message: response.message }
            }
            else {
                reducer.dispatch({ type: 'loading', value: { loading: false } })
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: response.message } })
                return { success: false, message: response.message }
            }

        } catch (error) {
            reducer.dispatch({ type: 'loading', value: { loading: false } })
            if (error instanceof Error) {
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
                return { success: false, message: error.message }
            }
            else {
                console.error(error)
                return { success: false, message: error }
            }
        }
    }

    public async restore(parameters: any): Promise<response> {
        try {
            let response: any = await fetch(`${Url()}/${'delete'}?${parameters}&action=restore`, {
                method: 'DELETE',
                mode: 'cors',
                headers
            })
            response = await response.json()

            if (response.success) {
                let modelName: string = ''
                for (let model of models) {
                    if (parameters.split('&').includes(`model=${model}`)) {
                        modelName = model
                    }
                }
                this.createActivity(modelName, response.message, 'restore')
                return { success: true, message: response.message }
            }
            else
                return { success: false, message: response.message }

        } catch (error) {
            if (error instanceof Error)
                return { success: false, message: error.message }
            else
                return { success: false, message: error }
        }
    }

    private async createActivity(model: string, _data: any, _action: 'create' | 'update' | 'delete' | 'restore'): Promise<void> {
        try {
            if (model !== 'activity') {
                // const body: object = {
                //     model: 'activity',
                //     branch: Branch('_id'),
                //     user,
                //     data,
                //     restored: action === 'restore' || ((model === 'debt') && !data.editable) ? true : false,
                //     type: action === 'create' ? 'primary' : action === 'update' ? 'info' : action === 'delete' ? 'danger' : 'warn',
                //     activity_model: model,
                //     name: action === 'create' ? `new ${reFormatText(model)} was created` : action === 'update' ? `${reFormatText(model)} data was modified` : action === 'delete' ? `${reFormatText(model)} data was deleted` : `${reFormatText(model)} data was restored`
                // }
                // this.create({ route: 'create' }, body)
            }
        } catch (error) {
            if (error instanceof Error) {
                Toast(error.message)
            }
            else {
                console.error(error)
            }
        }
    }

}

const API = new api()

export default API