import SettingsDialog from "./settings-dialog";
import SettingsHeader from "./settings-header";
import SettingsSubheader from "./settings-sub-header";
import SettingsThemePicker from "./settings-theme-picker";
import SettingsFontPicker from "./settings-font-picker";
import SettingsLanguagePicker from "./settings-language-picker";
import Translate from "../../helpers/translator";

const Settings: any = ({ isSettingsOpen, setTheme }: any) => {
  return (
    <SettingsDialog isSettingsOpen={isSettingsOpen} className='hide-on-print'>
      <SettingsHeader>
        {Translate('settings')}
      </SettingsHeader>
      <SettingsSubheader>
        {Translate('theme')}
      </SettingsSubheader>
      <SettingsThemePicker setTheme={setTheme} />
      <SettingsSubheader>
        {Translate('font')}
      </SettingsSubheader>
      <SettingsFontPicker setTheme={setTheme} />
      <SettingsSubheader>
        {Translate('language')}
      </SettingsSubheader>
      <SettingsLanguagePicker setTheme={setTheme} />
    </SettingsDialog>
  );
};

export default Settings;
