const mountObjects = {
    device: {
        sort: 'name',
        order: 1,
        condition: 'devices'
    },

    service: {
        sort: 'createdAt',
        order: -1,
        condition: 'services'
    },

    customer: {
        sort: 'name',
        order: 1,
        condition: 'customers'
    },

    debt: {
        sort: 'createdAt',
        order: -1,
        condition: 'debts'
    },

    role: {
        sort: 'name',
        order: 1,
        condition: 'roles'
    },

    user: {
        sort: 'createdAt',
        order: -1,
        condition: 'users'
    },

    shop: {
        sort: 'createdAt',
        order: -1,
        condition: 'shops'
    },

    payment: {
        sort: 'createdAt',
        order: -1,
        condition: 'payments'
    },

    activity: {
        sort: 'createdAt',
        order: -1,
        condition: 'activities'
    },

    store: {
        sort: 'name',
        order: 1,
        condition: 'stores'
    },

    store_product: {
        sort: 'name',
        order: 1,
        condition: 'storeProducts'
    },

    supplier: {
        sort: 'name',
        order: 1,
        condition: 'suppliers'
    },

    manufacturer: {
        sort: 'name',
        order: 1,
        condition: 'manufacturers'
    },

    purchase: {
        sort: 'createdAt',
        order: -1,
        condition: 'purchases'
    },

    expense: {
        sort: 'createdAt',
        order: -1,
        condition: 'expenses'
    },

    expense_type: {
        sort: 'name',
        order: 1,
        condition: 'expenseTypes'
    },
}

export default mountObjects