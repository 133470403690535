import React from 'react';
// import { Main } from '../../components/elements';
import styled from 'styled-components';
import Translate from '../../helpers/translator';
// import { FloatingActionButton } from '../../components/input';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
`;

const H3 = styled.h3`
  color: ${(p) => p.theme.colorLabel};
  font-size: 2em;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  color: ${(p) => p.theme.colorLabel};
  font-size: 16px;
  font-weight: ${(p) => p.theme.fontWeightLight};
`;

const Icon = styled.span`
    color: ${(p) => p.theme.colorPrimary};
    font-size: 5em !important;
    font-weight: ${(p) => p.theme.fontWeightLight};
`

const Offline: React.FunctionComponent<any> = () => {
    React.useEffect(() => {
        document.title = Translate('No internet');
        return () => { };
        // eslint-disable-next-line
    }, []);

    return (
        <div className='row'>
            <div className="col s12">
                <Wrapper>
                    <Icon className='material-icons-round'>wifi_off</Icon>
                    <H3>{Translate("Oops! no internet")}</H3>
                    <Paragraph>
                        {Translate("Sorry, you're not connected to the internet.")}
                    </Paragraph>
                </Wrapper>
            </div>
        </div>
    );
};

export default Offline;
