import { reFormatText, Retrive } from "./index"

const englishWords: string[] = [
    'settings',
    'theme',
    'font',
    'language',
    'english',
    'dashboard',
    'product',
    'add new product',
    'list products',
    'stock taking',
    'point of sale', 'add new sale', 'list sales', 'sale history', 'add new invoice', 'list invoice',
    'expense', 'add new expense', 'list expenses', 'expenses history', 'add new expense type', 'list expense types',
    'purchase', 'add new purchase', 'list purchases', 'purchases history',
    'supplier', 'add new supplier', 'list suppliers',
    'manufacturer', 'add new manufacturer', 'list manufacturers',
    'customer', 'customers', 'add new customer', 'list customers', 'customers history',
    'message', 'send new message', 'list messages',
    'report', 'generate new report', 'income statement',
    'payment', 'make new payment', 'list payments', 'payments history',
    'shop', 'add new shop', 'list shops', 'add new shop user', 'list shop users', 'list shop activities',
    'roles', 'add new user role', 'list user roles', 'view', 'profile', 'edit', 'delete', 'change password', 'edit profile', 'view profile',
    'enter your username or phone number',
    'welcome back!',
    'login', 'account', 'password', 'enter your account password', 'create new account', 'forgot password?', 'error', 'loading', 'required', 'account must have atleast three characters', 'password must have atleast 4 characters', 'learn more', 'close', 'account does not exist', 'password is not correct', 'failed to fetch', 'welcome you have been logged in', 'you have been logged out', 'failed to logout',
    'shop name',
    'enter your shop name',
    'shop type',
    'select shop type',
    'username',
    'enter your username',
    'phone number',
    'enter your phone number',
    'enter your password',
    'password confirmation',
    'confirm your password',
    'already registered?',
    'normal shop',
    'electronics and repair shop',
    'pharmacy',
    'username must have atleast 3 characters',
    'phone number must have 10 digits',
    'password must have atleast 8 characters',
    'password does not match',
    'username already exist',
    'phone number already exist',
    'you have been registered',
    'failed to send code',
    'register',
    'create account!',
    'send verification code',
    'verification code',
    'resend code',
    'enter verification code sent to your phone number',
    'verify',
    'verification code must have 6 digits',
    'verification code is not correct',
    'reset password',
    'create',
    'role',
    'create role',
    'name',
    'description',
    'enter role name',
    'enter role description',
    'role has been created',
    'role has been updated',
    'update role',
    'roles are required',
    'list',
    'name already exist',
    'failed to create role',
    'no role has been found',
    'swahili',
    'created by',
    'updated by',
    'phone number verified',
    'yes',
    'no',
    'created date',
    'updated date',
    'user information',
    'view user',
    'edit user',
    'delete user',
    'system users',
    'info',
    'user list',
    'number',
    'customer information',
    'edit customer',
    'delete customer',
    'view customer',
    'view role',
    'role information',
    'edit role',
    'delete role'
]

const swahiliWords: string[] = [
    'mipangilio',
    'muonekano',
    'muandiko',
    'lugha',
    'kiingereza',
    'dashibodi',
    'bidhaa',
    'ongeza bidhaa mpya',
    'orodha ya bidhaa',
    'kumbukumbu ya bidhaa',
    'sehemu ya mauzo', 'ongeza uuzaji mpya', 'orodha ya mauzo', 'historia ya uuzaji', 'ongeza ankara mpya', 'orodha ya ankara',
    'matumizi', 'ongeza matumizi', 'orodha ya matumizi', 'historia ya matumizi', 'ongeza aina ya matumizi', 'orodha ya aina za matumizi',
    'ununuzi', 'ongeza ununuzi mpya', 'orodha ya ununuzi', 'historia ya ununuzi',
    'msambazaji', 'ongeza msambazaji mpya', 'orodha ya wasambazaji',
    'mzalishaji', 'ongeza mzalishaji mpya', 'orodha ya wazalishaji',
    'mteja', 'wateja', 'ongeza mteja mpya', 'orodha ya wateja', 'historia ya wateja',
    'ujumbe', 'tuma ujumbe mpya', 'orodha ya ujumbe',
    'ripoti', 'toa ripoti mpya', 'taarifa ya mapato',
    'malipo', 'fanya malipo', 'orodha ya malipo', 'historia ya malipo',
    'duka', 'ongeza duka jipya', 'orodha ya maduka', 'ongeza mtumiaji mpya dukani', 'orodha ya watumiaji dukani', 'orodha ya shughuri dukani',
    'majukumu', 'ongeza jukumu jipya la mtumiaji', 'orodha ya majukumu ya watumiaji',
    'tazama', 'wasifu', 'hariri', 'futa', 'badili neno la siri', 'hariri wasifu', 'tazama wasifu',
    'ingiza jina lako au nambari ya simu',
    'karibu tena!',
    'ingia',
    'akaunti', 'neno la siri', 'ingiza neno la siri la akaunti yako', 'fungua akaunti mpya', 'umesahau neno la siri?', 'kuna kosa', 'inapakia', 'inahitajika', 'akaunti lazima iwe na angalau herufi tatu', 'neno la siri lazima liwe na angalau herufi 4', 'jifunze zaidi', 'funga', 'akaunti haipo', 'neno la siri si sahihi', 'tatizo la mtandao', 'karibu tena umeingia ndani ya mfumo', 'umeondoka ndani ya mfumo', 'imeshindwa kuondoka ndani ya mfumo',
    'jina la duka',
    'ingiza jina la duka',
    'aina ya duka',
    'chagua aina ya duka',
    'jina la mtumiaji',
    'ingiza jina la kutumia',
    'nambari ya simu',
    'ingiza nambari yako ya simu',
    'ingiza neno la siri',
    'uthibitisho wa neno la siri',
    'thibitisha neno lako la siri',
    'tayari umesajiliwa?',
    'duka la kawaida',
    'duka la elektroniki na ukarabati',
    'duka la dawa',
    'jina la mtumiaji lazima liwe na angalau herufi 3',
    'nambari ya simu lazima iwe na tarakimu 10',
    'Neno la siri lazima liwe na angalau herufi 8',
    'Neno la siri halilingani',
    'Jina la mtumiaji tayari lipo',
    'Nambari ya simu tayari ipo',
    'umesajiliwa',
    'imeshindwa kutuma nambari ya uthibitisho',
    'jisajili',
    'tengeneza akaunti!',
    'tuma namba ya uthibitisho',
    'nambari ya uthibitisho',
    'tuma tena nambari ya uthibitisho',
    'ingiza nambari ya kuthibitisho',
    'thibitisha',
    'nambari ya uthibitisho lazima iwe na tarakimu 6',
    'nambari ya uthibitisho sio sahihi',
    'weka upya neno la siri',
    'tengeneza',
    'jukumu',
    'tengeneza jukumu',
    'jina',
    'maelezo',
    'ingiza jina la jukumu',
    'ingiza maelezo ya jukumu',
    'jukumu limetengenezwa',
    'jukumu limebadilishwa',
    'hariri jukumu',
    'majukumu yanahitajika',
    'orodhesha',
    'jina tayari lipo',
    'imeshindwa kutengeneza jukumu',
    'hakuna jukumu lililopatikana',
    'swahili',
    'mtengenezaji',
    'mrekebishaji',
    'nambari ya simu imethibitishwa',
    'ndio',
    'hapana',
    'tarehe ya utengenezaji',
    'tarehe ya urekebishaji',
    'taarifa za mtumiaji',
    'tazama mtumiaji',
    'hariri mtumiaji',
    'futa mtumiaji',
    'watumiaji wa mfumo',
    'taarifa',
    'orodha ya watumiaji',
    'nambari',
    'taarifa za mteja',
    'hariri mteja',
    'futa mteja',
    'tazama mteja',
    'tazama jukumu',
    'taarifa za jukumu',
    'hariri jukumu',
    'futa jukumu'
]

export default function Translate(text: string): string {
    try {
        if ((text !== undefined) && (text.trim() !== '')) {
            if (swahiliWords?.length === englishWords?.length) {
                const currentLanguage: string = Retrive({ key: 'language' })
                text = reFormatText(text)
                // console.log(currentLanguage, text)

                if (currentLanguage === 'English' && (text?.length >= 0))
                    return text.charAt(0).toUpperCase() + text.slice(1)
                else if ((currentLanguage === 'Swahili' && (text?.length >= 0))) {
                    const textIndex: number = englishWords.indexOf(text)
                    if (textIndex >= 0) {
                        const word: string = swahiliWords[textIndex]
                        return word.charAt(0).toUpperCase() + word.slice(1)
                    }
                    else
                        return text.charAt(0).toUpperCase() + text.slice(1)
                }
                else {
                    return `${text[0].toUpperCase()}${text?.substring(1)}`
                }
            }
            else {
                return text.charAt(0).toUpperCase() + text.slice(1)
            }
        }
        else
            return ''
    } catch (error) {

        return text.charAt(0).toUpperCase() + text.slice(1)
    }
}