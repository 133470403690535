import styled from "styled-components";
import { v4 as uidv4 } from "uuid";
import Translate from "../../helpers/translator";
import useStyledTheme from "../../hooks/useStyledTheme";

const StyledWrapper: any = styled.div``;

const StyledLanguageLabel: any = styled.label`
  display: flex;
  align-items: center;
  height: ${(p: any) => p.theme.lenLg1};
  cursor: pointer;

  & > input {
    position: absolute;
    left: -9999px;
  }
`;

const StyledLanguageText: any = styled.span`
  color: ${(p: any) => (p.isActive ? p.theme.colorText : p.theme.colorLabel)};
  margin-left: ${(p) => p.theme.lenSm2};
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 300;
`;

const StyledRadioButton: any = styled.span`
  position: relative;
  width: ${(p: any) => p.theme.lenMd1};
  height: ${(p: any) => p.theme.lenMd1};
  border-radius: 50%;
  border: 2px solid
    ${(p: any) => (p.isActive ? p.theme.colorPrimary : p.theme.colorBorder)};

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${(p: any) => p.theme.lenSm2};
    height: ${(p: any) => p.theme.lenSm2};
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(${(p: any) => (p.isActive ? 1 : 0)});
    background: ${(p: any) => p.theme.colorPrimary};
    transition: transform 0.25s;
  }
`;

const languages: string[] = [
    "English",
    "Swahili",
];

const SettingsLanguagePicker: any = ({ setTheme }: any) => {
    const theme: any = useStyledTheme();
    const isActive = (language: any) => language === theme.language;

    return (
        <StyledWrapper>
            {languages.map((language: any) => (
                <StyledLanguageLabel
                    key={uidv4()}
                    htmlFor={language}
                    onChange={() => setTheme(theme, theme.fontFamily, language)}
                >
                    <input type="radio" id={language} name="language" />
                    <StyledRadioButton isActive={isActive(language)}></StyledRadioButton>
                    <StyledLanguageText isActive={isActive(language)}>{Translate(language)}</StyledLanguageText>
                </StyledLanguageLabel>
            ))}
        </StyledWrapper>
    );
};

export default SettingsLanguagePicker;
