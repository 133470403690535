import { Branch, formatText, getCondition, isUser, User, visible } from ".";
import { reducer, filterOptions, get, response, deleteOptions, searchOptions, paginationOptions, mountOptions } from "../types"
import API from "./api";
import mountObjects from "./mount";

class Functions {
    public filter(filter: filterOptions, reducer: reducer): void {
        try {
            if ((reducer.state.order !== filter.order) || (reducer.state.limit !== filter.limit) || (reducer.state.sort !== filter.sort) || (reducer.state.condition !== filter.condition)) {
                let backendSort: string;

                if (filter.sort === 'time created')
                    backendSort = JSON.stringify({ createdAt: filter.order })
                else
                    backendSort = JSON.stringify({ [filter.sort.replace(/[^\w]/gi, '_')]: filter.order })

                const backendCondition: string = JSON.stringify(isUser ? { ...getCondition(filter.condition), ...reducer.state.propsCondition, visible } : { ...reducer.state.propsCondition, visible })

                const parameters: string = `schema=${filter.module}&condition=${backendCondition}&sort=${backendSort}&page=${reducer.state.page}&limit=${filter.limit}`
                console.log(filter.module)
                const options: get = {
                    type: filter.type,
                    route: 'list',
                    parameters,
                    sort: filter.sort,
                    order: filter.order,
                    condition: filter.condition
                }
                reducer.dispatch({ type: 'limit', value: { limit: filter.limit } })
                API.get(options, reducer)
            }
        } catch (error) {
            if (error instanceof Error) {
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
            }
            else {
                console.error(error)
            }
        }
    }

    public async delete(deleteOptions: deleteOptions, reducer: reducer): Promise<response> {
        try {
            console.log(deleteOptions)
            this.toggleDialog('close', '', reducer)
            const options: get = {
                type: deleteOptions.type,
                route: 'api/delete',
                parameters: `schema=${deleteOptions.module}&id=${reducer.state.id}&action=delete`
            }
            return await API.delete(options, reducer)
        } catch (error) {
            if (error instanceof Error)
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
            else
                console.error(error)
            return { success: false, message: error }
        }
    }

    public toggleDialog(action: 'close' | 'open', id: string, reducer: reducer): void {
        try {
            if (action === 'open') {
                reducer.dispatch({ type: 'isDialogOpen', value: { isDialogOpen: true } })
                reducer.dispatch({ type: 'id', value: { id } })
            }
            else {
                reducer.dispatch({ type: 'isDialogOpen', value: { isDialogOpen: false } })
                reducer.dispatch({ type: 'id', value: { id: '' } })
            }
        } catch (error) {
            if (error instanceof Error)
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
            else
                console.error(error)
        }
    }

    public search(searchOptions: searchOptions, reducer: reducer): void {
        try {
            const { value }: any = searchOptions.event.target

            if (value.trim() !== '') {
                const condition: string = JSON.stringify(isUser ? { ...getCondition(reducer.state.condition), ...reducer.state.propsCondition, visible } : { ...reducer.state.propsCondition, visible })
                const parameters: string = `schema=${searchOptions.module}&keyword=${formatText(value)}&condition=${condition}`
                const options: get = {
                    type: searchOptions.type,
                    route: 'api/search',
                    parameters,
                    sort: reducer.state.sort,
                    order: reducer.state.order,
                    condition: reducer.state.condition
                }
                API.get(options, reducer)
            }
        } catch (error) {
            if (error instanceof Error)
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
            else
                console.log(error)
        }
    }

    public paginate(paginationOptions: paginationOptions, reducer: reducer): void {
        try {
            if ((reducer.state.page !== paginationOptions.page) && (paginationOptions.page !== 0)) {
                const condition: string = JSON.stringify(isUser ? { ...getCondition(reducer.state.condition), ...reducer.state.propsCondition, visible } : { ...reducer.state.propsCondition, visible })
                const sort: string = JSON.stringify({ [reducer.state.sort === 'time created' ? "createdAt" : reducer.state.sort.replace(/[^\w]/gi, '_')]: reducer.state.order })
                const parameters: string = `schema=${paginationOptions.module}&condition=${condition}&sort=${sort}&page=${paginationOptions.page}&limit=${reducer.state.limit}`
                const options: get = {
                    type: paginationOptions.type,
                    route: 'list',
                    parameters,
                    sort: reducer.state.sort,
                    order: reducer.state.order,
                    condition: reducer.state.condition
                }
                API.get(options, reducer)
            }
        } catch (error) {
            if (error instanceof Error)
                reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
            else
                console.log(error)
        }
    }

    public mount(mountOptions: mountOptions): void {
        try {
            let backendCondition: object = {}
            if (mountOptions.props.location.state) {
                const { propsCondition }: any = mountOptions.props.location.state
                if (propsCondition) {
                    backendCondition = propsCondition
                    mountOptions.props.application.reducer.dispatch({ type: 'propsCondition', value: { propsCondition: propsCondition } })
                }
            }
            const sort = JSON.stringify({ [mountObjects[mountOptions.type].sort]: mountObjects[mountOptions.type].order })
            const condition = JSON.stringify(isUser && mountObjects[mountOptions.type].condition !== 'shops' ? { branch: Branch('_id'), ...backendCondition, visible: true } : isUser && mountObjects[mountOptions.type].condition === 'shops' ? { user: User('_id'), ...backendCondition, visible: true } : { ...backendCondition, visible: true })
            const options: get = {
                type: mountObjects[mountOptions.type].condition,
                route: 'list',
                parameters: `schema=${mountOptions.type === 'shop' ? 'branch' : mountOptions.type}&condition=${condition}&sort=${sort}&limit=${mountOptions.props.application.reducer.state.limit}&page=1`,
                sort: mountObjects[mountOptions.type].sort,
                order: mountObjects[mountOptions.type].order,
                condition: mountObjects[mountOptions.type].condition
            }
            API.get(options, mountOptions.props.application.reducer)
        } catch (error) {
            if (error instanceof Error)
                mountOptions.props.application.reducer.dispatch({ type: 'loadingMessage', value: { loadingMessage: error.message } })
            else
                console.error(error)
        }
    }
}

const functions = new Functions()

export default functions