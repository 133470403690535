import { Route } from 'react-router-dom'
import { route } from '../types'
import routes from './routes'
import { Switch } from 'react-router-dom'

export default function Routing(application): any {
    return (
        <Switch>
            {
                routes.map((route: route, index: number) => {
                    return (
                        <Route
                            key={index}
                            exact
                            path={route.path}
                            render={(props) => (<route.component {...props} application={application} />)}
                        />
                    )
                })
            }
        </Switch>
    )
}

