import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import menus from '../../config/menus'
import { menu, submenu } from '../../types'
import Translate from '../../helpers/translator'
import AuthContext from '../../hooks/authContext'


const MenuWrapper = styled.div`
    position: fixed;
    left: 25px;
    bottom: 25px;
    z-index: 1;
    display: none;
    transition: all 0.5s ease-in;
    @media only screen and (max-width: 992px) {
        transition: all 0.5s ease-in;
        display: block;
    }
`

const Menu = styled.div`
    color: ${(props: any) => props.theme.colorPrimaryText};
    position: relative;
    overflow: hidden;
    width: 56px;
    height: 56px;
    line-height: 40px;
    padding: 0;
    background-color: ${(props: any) => props.theme.colorPrimary};
    border-radius: 50%;
    cursor: pointer;
    &:hover {
        opacity: .8
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const MenuIcon = styled.span`
    display: block;
    padding: 0.75rem;
    cursor: pointer;
    &:hover {
        transition: all 0.3s ease;
        background: rgba(0, 0, 0, 0.1);
    }
`
const Sidebar = styled.div`
    position: fixed;
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    left: 0;
    top: 0;
    margin: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    height: 100%;
    overflow-y: auto;
    will-change: transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    background: ${(props: any) => props.theme.colorNavbar};
    transition: transform 0.3s;
    @media only screen and (max-width: 992px) {
        -webkit-transform: translateX(${(props: any) => props.theme.isNavbarOpen ? '0%' : '-105%'});
        transform: translateX(${(props: any) => props.theme.isNavbarOpen ? '0%' : '-105%'})
    }
    z-index: 10;
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    padding-bottom: ${(props) => props.theme.lenMd1};
`
const LogoDetail = styled.div`
    height: 56px;
    line-height: 56px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`

const LogoIcon = styled.img`
    min-width: 50px;
    height: 40px;
`
const NavLinks = styled.ul`
margin-top: 10px;
    height: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
        display: none
    }
`
const List = styled.li<{ active: boolean }>`
    list-style-type: none;
    position: relative;
    cursor: pointer;
    &:hover {
    background: ${(props) => props.theme.colorDefaultBackground};
    border-left: 5px solid ${(props) => props.theme.colorPrimary}; 
    }
    border-left: 5px solid ${(props: any) => props.active ? props.theme.colorPrimary : props.theme.colorNavbar};
    background: ${(props: any) => props.active ? props.theme.colorDefaultBackground : ''};
    
`
const NavLink = styled(Link)`
    text-decoration: none;  
    display: flex;
    align-items: center;
    line-height: 50px;
    height:50px;
    outline: none;
`
const NavLinkIcon = styled.span`
    min-width: 70px;
    text-align: center;
    line-height: 56px;
    color: ${(props) => props.theme.colorNavbarLabel};
    font-size: 1.5em !important;
`
const NavLinkName = styled.span`
    font-size: 14px;
    font-weight: ${(props) => props.theme.fontWeightSemibold};
    text-transform: capitalize;
    color: ${(props) => props.theme.colorNavbarLink};
`

const SubMenu = styled.ul`
    padding: 5px 5px 5px 60px;
`
const SubMenuList = styled(Link)`
    color: ${(props) => props.theme.colorNavbarLink};
    text-decoration: none;
    display: flex;
    align-items: center;
    &:hover {
        color: ${(props) => props.theme.colorPrimary};
    }
`
const SubMenuLink = styled.span<{ active: boolean }>`
    font-size: 12px;
    text-transform: capitalize;
    color: ${(props) => props.active ? props.theme.colorPrimary : props.theme.colorNavbarLink};
    &:hover {
        color: ${(props) => props.theme.colorPrimary};
        font-size: 12px;
    }
`

const SubMenuIcon = styled.span``


const Navbar: React.FunctionComponent<any> = ({ toggleNavbar }) => {
    const { logout }: any = React.useContext(AuthContext)
    const [activeIndex, setActiveIndex] = React.useState(0)
    const [activeMenu, setActiveMenu] = React.useState('')
    const [activeSubMenu, setActiveSubMenu] = React.useState('')

    function toggleMenu(menu: menu, index: number): void {
        if (index !== activeIndex) {
            setActiveIndex(index)
            setActiveMenu(menu.name)
            setActiveSubMenu('')

            if (menu.hasSubMenus === false) {
                toggleNavbar(false)
            }
        }
        else {
            setActiveIndex(0)
            setActiveMenu(menu.name)
            if (menu.hasSubMenus === false) {
                toggleNavbar(false)
            }
        }

    }

    function activateSubMenu(menu: submenu) {
        setActiveSubMenu(menu.name)
        toggleNavbar(false)
    }

    return (
        <React.Fragment>
            <Sidebar className="hide-on-print">
                <LogoDetail>
                    <LogoIcon src="/images/icon-2.png" />
                </LogoDetail>
                <NavLinks>
                    {
                        menus.map((menu: menu, index: number) => {
                            if (menu.visible) {
                                return (
                                    <List active={menu.name === activeMenu} key={index} onClick={() => toggleMenu(menu, index)}>
                                        <NavLink to={menu.link}>
                                            <NavLinkIcon className="material-icons-round">{menu.icon}</NavLinkIcon>
                                            <NavLinkName>{Translate(menu.name)}</NavLinkName>
                                        </NavLink>
                                        {
                                            menu.hasSubMenus && (activeIndex === index)
                                                ? menu.subMenus.map((submenu: submenu, index: number) => {
                                                    if (submenu.visible) {
                                                        return (
                                                            <SubMenu key={index}>
                                                                <SubMenuList to={submenu.link} onClick={() => activateSubMenu(submenu)} >
                                                                    <SubMenuIcon className="material-icons">chevron_right</SubMenuIcon>
                                                                    <SubMenuLink active={submenu.name === activeSubMenu ? true : false}>
                                                                        {Translate(submenu.name)}
                                                                    </SubMenuLink>
                                                                </SubMenuList>
                                                            </SubMenu>
                                                        )
                                                    }
                                                    else {
                                                        return null
                                                    }
                                                })
                                                : null
                                        }
                                    </List>
                                )
                            }
                            else {
                                return null
                            }
                        })
                    }
                    <List active={false} onClick={logout}>
                        <NavLink to="#">
                            <NavLinkIcon className="material-icons-round">power_settings_new</NavLinkIcon>
                            <NavLinkName>{Translate('logout')}</NavLinkName>
                        </NavLink>
                    </List>
                </NavLinks>
            </Sidebar>
            <MenuWrapper>
                <Menu onClick={toggleNavbar}>
                    <MenuIcon className="material-icons-round">menu</MenuIcon>
                </Menu>
            </MenuWrapper>
        </React.Fragment>
    )
}

export default Navbar