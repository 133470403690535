import styled, { css } from "styled-components";
import Unicon from "./unicon";


const primaryBadge: any = css`
  fill: ${(p: any) => p.theme.colorPrimary};

  & span {
    color: ${(p: any) => p.theme.colorPrimaryText};
  }
`;

const accentBadge: any = css`
  fill: ${(p: any) => p.theme.colorAccent};

  & span {
    color: ${(p: any) => p.theme.colorAccentText};
  }
`;

const defaultBadge: any = css`
  fill: ${(p: any) => p.theme.colorDefaultBackground};

  & span {
    color: ${(p: any) => p.theme.colorDefaultText};
  }
`;

const smBadge: any = css`
  width: ${(p: any) => p.theme.lenSm1};
  height: ${(p: any) => p.theme.lenSm1};
`;

const mdBadge: any = css`
  width: ${(p: any) => p.theme.lenSm2};
  height: ${(p: any) => p.theme.lenSm2};
`;

const lgBadge: any = css`
  width: ${(p: any) => p.theme.lenSm3};
  height: ${(p: any) => p.theme.lenSm3};
`;

const xlBadge = css`
  width: ${(p: any) => p.theme.lenLg1};
  height: ${(p: any) => p.theme.lenLg1};
`;

const xxlBadge: any = css`
  width: ${(p: any) => p.theme.lenXl1};
  height: ${(p: any) => p.theme.lenXl1};
`;

const StyledOuterWrapper: any = styled.span``;

const StyledWrapper: any = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p: any) => p.size === "sm" && smBadge};
  ${(p: any) => p.size === "md" && mdBadge};
  ${(p: any) => p.size === "lg" && lgBadge};
  ${(p: any) => p.size === "xl" && xlBadge};
  ${(p: any) => p.size === "xxl" && xxlBadge};
`;

const StyledSvgWrapper: any = styled.span`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledBadgeSvg: any = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledBadgeCircle: any = styled.circle`
  ${(p: any) => p.isPrimary && primaryBadge};
  ${(p: any) => p.isAccent && accentBadge};
  ${(p: any) => p.isDefault && defaultBadge};
`;

const StyledIcon: any = styled.span`
  position: relative;
  z-index: 2;

  & span {
    color: white;
  }
`;

const Badge: any = ({
    size = "md",
    fill,
    icon,
    isPrimary,
    isAccent,
    isDefault,
    ...rest
}: any) => {

    return (
        <StyledOuterWrapper {...rest}>
            <StyledWrapper size={size}>
                <StyledSvgWrapper>
                    <StyledBadgeSvg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <StyledBadgeCircle
                            isPrimary={isPrimary}
                            isAccent={isAccent}
                            isDefault={isDefault}
                            fill={fill || "transparent"}
                            cx="4"
                            cy="4"
                            r="4"
                        />
                    </StyledBadgeSvg>
                </StyledSvgWrapper>
                {icon && (
                    <StyledIcon>
                        <Unicon>{icon}</Unicon>
                    </StyledIcon>
                )}
            </StyledWrapper>
        </StyledOuterWrapper>
    );
};

export default Badge;
