import styled from "styled-components";

const StyledIcon:any = styled.span`
  font-size: inherit;
`;

const Unicon = ({ children }:any) => {
  return <StyledIcon className={`uil uil-${children}`} />;
};

export default Unicon;
