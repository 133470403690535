import { rgba } from "polished";
import defaultTheme from "./defaultTheme";

const colorLabel = rgba("white", 0.57);
const colorText = rgba("white", 0.96);

const darkSmasTheme: any = {
    id: "darkSmas",
    ...defaultTheme,
    borderRadius: "1rem",
    colorBackground: "#242424",
    colorNavbar: "#121212",
    colorNavbarLabel: colorLabel,
    colorNavbarLink: colorText,
    colorText: colorText,
    colorLabel: colorLabel,
    colorBorder: "#242424",
    colorPrimary: "#3f51b5",
    colorPrimaryText: colorText,
    colorAccent: "#5c5c5c",
    colorAccentText: "#1B1B1B",
    colorCardBackground: "#121212",
    colorDefaultBackground: "#2D2D2D",
    colorDefaultText: colorText,
    colorDisabledBackground: "#3d3d3d",
    colorDisabledText: "#6b6666",
    colorCode: "#242424",
    colorChartShading: "white",
    boxShadowLogo: "none",
    colorDanger: '#f44336',
    colorInfo: colorLabel,
    colorWarn: '#ff9800',
    colorSuccess: '#4caf50'
};

export default darkSmasTheme