import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { ThemeProvider, createGlobalStyle } from "styled-components"


const GlobalStyle = createGlobalStyle`
* {
    font-family: ${(props) =>
        props.theme
            .fontFamily}, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif;
    box-sizing: border-box;
    margin:0;
    padding: 0;
  }

    body {
        background: ${(props: any) => props.theme.colorBackground};
        color: ${(props: any) => props.theme.colorText};
        // max-width: ${(props) => props.theme.screenWidthXl};
      }
    ::-webkit-scrollbar {
        width: 5px;
        scroll-behavior: smooth;
    }

    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colorBackground}; 
    }
    
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colorPrimary}; 
    }
`

const ThemeWrapper: React.FunctionComponent<any> = ({ children, theme }: any) => {
    return (
        <ThemeProvider theme={theme}>
            <HelmetProvider>
                <GlobalStyle />
                <Helmet>
                    <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet" />
                    <meta name="theme-color" content={theme.colorBackground} />
                    {
                        theme.fontFamily === "Product Sans"
                            ? <link
                                id="font-script"
                                rel="stylesheet"
                                href={`https://fonts.googleapis.com/css?family=Product+Sans:100,300,400,500,700,900,100i,300i,400i,500i,700i,900i`}
                            />
                            : theme.fontFamily === "Segoe UI"
                                ? <link href="http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18006,18005,18004,18003" rel="stylesheet" />
                                : theme.fontFamiliy === "Helvetica Neue"
                                    ? <link href="http://db.onlinewebfonts.com/c/9da41c570d5a221cb2486d78769a076d?family=Neue+Helvetica" rel="stylesheet" type="text/css" />
                                    :
                                    <link
                                        id="font-script"
                                        rel="stylesheet"
                                        href={`https://fonts.googleapis.com/css2?family=${theme.fontFamily}:wght@${theme.fontWeightLight};${theme.fontWeightRegular};${theme.fontWeightSemibold};${theme.fontWeightBold}&display=swap`}
                                    />
                    }
                </Helmet>
                {children}
            </HelmetProvider>
        </ThemeProvider>
    )
}

export default ThemeWrapper


